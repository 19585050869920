import { tablet } from 'constants/breakpoints'
import { grayGainsboro } from 'constants/colors'
import { redLust } from 'constants/colors'
import { blueSapphire } from 'constants/colors'
import { cultured } from 'constants/colors'
import { sonicSilver } from 'constants/colors'
import { white } from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div``
export const NavBar = styled.div`
  width: 988px;
  max-width: 988px;

  justify-content: space-between;

  @media (max-width: 994px) {
    width: 100%;
  }

  > a {
    width: 100%;
  }
`
export const Logo = styled.img`
  width: 100%;
  max-height: 205px;
  object-fit: contain;
`
export const NavItemContainer = styled.div`
  width: 430px;
  height: 40px;
  max-width: 430px;
  max-height: 40px;
  gap: 12px;

  @media (max-width: 994px) {
    display: none;
  }
`

export const NavItemMobileContainer = styled.div`
  position: relative;
  display: none;
  > button {
    outline: none;
    border: none;
    background-color: transparent;
    width: 28px;
    height: 28px;
  }
  @media (max-width: 994px) {
    display: block;
  }
`

export const NavItemMobileItemWrapper = styled.div`
  display: none;
  background-color: ${cultured};
  box-shadow: rgb(42 54 66 / 20%) 0px 16px 18px 0px;
  @media (max-width: 994px) {
    display: block;
  }

  position: absolute;
  right: 0;
  z-index: 100;
  padding: 12px;
  border-radius: 4px;
`

export const NavItem = styled.a`
  display: flex;

  height: 40px;
  min-width: 40px;
  max-height: 40px;
  padding: 12px;

  border: 1px solid ${grayGainsboro};
  border-radius: 8px;

  color: ${white};

  &:hover {
    color: ${white};
    text-decoration: none;
  }

  @media (max-width: 994px) {
    border: none;
    padding: 0;
  }
`
export const Icon = styled.span`
  display: inline-block;
  background: url(${(props) => props.url}) center center no-repeat;
  background-size: 100%;

  width: 17.88px;
  height: 13.75px;
`
export const IconPhone = styled(Icon)`
  height: 17.16px;
`
export const IconSquare = styled(Icon)`
  height: 17.88px;
  min-width: 17.88px;
`
export const NavContent = styled.span`
  display: inline-block;
  margin-left: 6px;

  @media (max-width: 994px) {
    color: ${redLust};
  }
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;

  width: 240px;
  height: 40px;

  background: ${white};

  border: 1px solid ${grayGainsboro};
  border-radius: 8px;

  flex: none;
  order: 3;
  flex-grow: 0;

  @media (max-width: 994px) {
    width: 100%;
  }
`

export const SearchInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 196px;
  height: 24px;
  border: none;
  outline: none;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  color: ${sonicSilver};
  opacity: 0.75;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

  min-width: 145px;

  @media (max-width: ${tablet}) {
    display: none;
  }
`

export const ISO = styled.span`
  display: inline-block;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: ${white};
  letter-spacing: 0;
`

export const BannerInfo = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;

  height: 100%;
  width: 100%;
  padding: 6px 12px;

  gap: 12px;
`

export const TextNormal = styled.span`
  display: inline-block;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  color: ${white};
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
`

export const TextLarge = styled(TextNormal)`
  font-size: 34px;
  line-height: 34px;
  font-weight: 900;
`

export const TextSmall = styled(TextNormal)`
  font-size: 16px;
  font-weight: bold;
  text-align: ${(props) => props.textAlign || 'left'};
  min-width: 120px;
`

export const CustomBanner = styled.div`
  display: flex;
  align-items: center;
  min-height: 125px;
  width: 100%;
  background-color: royalblue;

  filter: drop-shadow(rgba(0, 0, 0, 0.07) 0px 4px 3px) drop-shadow(rgba(0, 0, 0, 0.06) 0px 2px 2px);

  @media (max-width: ${tablet}) {
    ${TextNormal} {
      font-size: 18px;
    }

    ${TextLarge} {
      font-size: 24px;
    }

    ${TextSmall} {
      font-size: 12px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
