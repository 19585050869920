import { tablet } from 'constants/breakpoints'
import { smallScreenLimit } from 'constants/breakpoints'
import { blueSapphire } from 'constants/colors'
import { cultured } from 'constants/colors'
import { redLust } from 'constants/colors'
import { darkLiver, blackRaisin } from 'constants/colors'
import styled from 'styled-components'

export const Content = styled.div`
  align-items: flex-start;
`

export const Thumbnail = styled.div`
  height: 120px;
  margin-right: 20px;
  aspect-ratio: ${(props) => props.ratio};

  background: url(${(props) => props.bg}) center center no-repeat;
  background-size: cover;
  background-color: ${cultured};

  @media (max-width: ${smallScreenLimit}) {
    height: 96px;
    margin-right: 8px;
  }
  @media (max-width: 300px) {
    height: 84px;
    margin-right: 8px;
  }
`

export const Info = styled.div`
  width: 100%;
  min-width: 45%;
`

export const Title = styled.div`
  color: ${blackRaisin};
  font-style: normal;

  line-height: 26px;
  margin-bottom: 8px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  @media (max-width: ${smallScreenLimit}) {
    -webkit-line-clamp: 2;
  }
`

export const Description = styled.div`
  color: ${darkLiver};
  font-style: normal;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;

  max-height: 115px;

  img {
    display: none;
  }
`

export const CreatedAt = styled.div`
  color: rgba(117, 117, 117, 0.7);
  font-style: normal;

  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  margin: 16px 0;

  border: 1px solid rgba(0, 0, 0, 0.08);

  ${(props) =>
    props.type === 'feed_vertical'
      ? `
      display: none;
    `
      : ''};
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 652px;
  height: 115px;

  flex: none;
  order: 0;
  flex-grow: 0;

  a {
    display: block;
    width: 100%;
    text-decoration: unset !important;
  }

  @media (min-width: ${smallScreenLimit}) {  
    ${(props) =>
      props.type === 'feed_vertical'
        ? `
      flex-flow: column;
      height: 100%;
      width: 316px;
    `
        : ''};
  }

  @media (max-width: ${smallScreenLimit}) {
    width: 100%;
  }
  @media (max-width: 650px) {
    height:100%;
  }

  @media (min-width: ${smallScreenLimit}) {
    ${Content} {
      flex-flow: ${(props) => (props.type === 'feed_vertical' ? 'column' : 'row')};
    }

    ${Info} {
      ${(props) =>
        props.type === 'feed_vertical'
          ? `
      width: 316px;
      max-width: 316px;

      margin-top: 14px;
    `
          : ''};
    }
    ${Title} {
      ${(props) =>
        props.type === 'feed_vertical'
          ? `
      font-size: 17px;
      line-height: 24px;
      -webkit-line-clamp: 2;
    `
          : ''};
    }
  }

  &:hover {
    ${Title} {
      color: ${blueSapphire};
    }
  }
`

export const VerticalFeed = styled.div`
  display: flex;
  justify-content: space-between;
  width: 652px;
  padding-bottom: 16px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  @media (max-width: ${smallScreenLimit}) {
    width: 100%;
    flex-flow: column;
  }
`

export const VerticalFeedDivider = styled.div`
  height: 386px;
  border: 1px solid rgba(0, 0, 0, 0.08);

  @media (max-width: ${smallScreenLimit}) {
    width: 100%;
    height: 0px;
    margin: 16px 0;
  }
`

export const MoreInfo = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
  gap: 12px;
  > a {
    flex: 1;
    min-width: 30%;
  }
`

export const MoreInfoItem = styled.span`
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  width: 188px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  color: ${blackRaisin};
  word-break: break-all;

  @media (max-width: ${smallScreenLimit}) {
    width: 100%;
  }

  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    margin-top: 8px;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    min-width: 7px;
    min-height: 7px;
    border-radius: 50%;
    margin-right: 12px;
    background: ${redLust};
    margin-top: 7px;
  }
`

export const CategoryContainer = styled.div`
  width: 100%;
`

export const CategoryDivider = styled.div`
  width: 100%;
  height: 2px;
  margin-bottom: 28px;
  margin-top: ${(props) => props.marginTop || 0}px;

  background: ${blueSapphire};
  border-radius: 12px 12px 0px 0px;
`
