import React from 'react'
import { SCHEDULE_DATA } from 'constants/serviceOption'
import companyInfo from 'constants/companyInfo'
import { Link } from 'react-router-dom'
import './TheFooter.scss'
import QRApp from "../../assets/new-icons/QRApp.jpg";
import BApp from "../../assets/new-icons/BApp.png";
import BCHPlay from "../../assets/new-icons/BCHPlay.png";
import QRPlay from "../../assets/new-icons/QRPlay.jpg";
import Facebook from "../../assets/new-icons/facebook.png";
import Tiktok from "../../assets/new-icons/tiktok.png";
import Youtube from "../../assets/new-icons/youtube.png";
import Zalo from "../../assets/new-icons/zalo.png";
import { useTranslation } from 'react-i18next'

export const TheFooter = (props) => {
  const { t: translation } = useTranslation()
  const LIST_POLICY = [
    {
      label: translation('rule'),
      href: '/general-policy'
    },
    {
      label: translation('privacy-policy'),
      href: '/privacy-policy'
    },
    {
      label: translation('terms-of-use'),
      href: '/policy'
    }
  ]

  const LIST_CONTACT = [
    {
      label: translation('Instruct'),
      href: '/instruction'
    },
    {
      label: translation('header.Contact_for_cooperation'),
      href: '/contact-cooperation'
    }
  ]
  const LIST_SOCIAL_NETWORK = [
    {
      icon:Facebook,
      href:'https://www.facebook.com/groups/940007330455923'
    },
    {
      icon:Zalo,
      href:'https://zalo.me/ttdk2023'
    },
    {
      icon:Tiktok,
      href:'https://www.tiktok.com/@ttdkdatlichdangkiem?_t=8gGKdPGglGM&_r=1'
    },
    {
      icon:Youtube,
      href:'https://www.youtube.com/@TTDK-DatLichDangKiem/about'
    },
  ]

  const LIST_INFO_COMPANY = [
    {
      QR : QRPlay,
      button : BCHPlay
    },
  ]

  const appStore = [
    {
      QR : QRApp,
      button : BApp
    }
  ]

  const mapperListIncludeLink = (item) => {
    return (
      <li>
        <Link to={item.href}>{item.label}</Link>
      </li>
    )
  }

  const LIST_FOOTER_METADATA = [
    [
      {
        title: translation('service'),
        list: SCHEDULE_DATA.map((_service) => {
          if (!_service.disable) {
            return <li key={_service.id}>{translation(_service.title)}</li>
          } else {
            return <></>
          }
        })
      }
    ],
    [
      {
        title: translation('Policy'),
        list: LIST_POLICY.map(mapperListIncludeLink)
      }
    ],
    [
      {
        title: translation('landing.contact'),
        list: LIST_CONTACT.map(mapperListIncludeLink),
        social:(
        <div className='' style={{display: '-webkit-inline-box',gap: '15px',margin: '10px 0'}}>
          {LIST_SOCIAL_NETWORK.map(item=>(
              <div className='d-flex'>
                <a target='_blank' href={item.href}><img style={{width:'40px',height:'40px'}} src={item.icon} alt="" /></a>
              </div>
          ))}
        </div>)
      }
    ],
    [
      {
        title: translation('download'),
        list: LIST_INFO_COMPANY.map((_item) => 
        <div>
          <div>
             <img src={_item.QR} width="145px" height="145px" />
          </div>
          <div onClick={() =>handleChPlay()} className='cursor'>
             <img src={_item.button} width="170px" height="70px" style={{ marginLeft : -12, marginTop : 18 }}/>
          </div>
        </div>),
        store: appStore.map((_item) => 
        <div>
          <div>
             <img src={_item.QR} width="145px" height="145px" />
          </div>
          <div className='style_app cursor' onClick={() =>handleApp()}>
             <img src={_item.button} width="155px" height="95px"/>
          </div>
        </div>),
      }
    ]
  ]

  const handleChPlay = () =>{
    window.open('https://play.google.com/store/apps/details?id=com.ttdk', '_blank')
  }

  const handleApp = () =>{
    window.open('https://apps.apple.com/vn/app/ttdk-%C4%91%E1%BA%B7t-l%E1%BB%8Bch-%C4%91%C4%83ng-ki%E1%BB%83m/id1663487095?l=vi', '_blank')
  }

  return (
    <>
      <div className="footer-mobile" />
      <footer className="footer">
        <div className="footer">
          <div className="secarea">
            <div className="container">
              {LIST_FOOTER_METADATA.map((_column) => {
                return (
                  <div className="one_fourth" key={Math.random()}>
                    <div className="widget-area">
                      {_column.map((_col) => {
                        return (
                          <aside className="widget">
                            <h3 className="widget-title">
                              <span>{_col.title}</span>
                            </h3>
                            <div className="execphpwidget">
                              <ul className="foolist">{_col.list}</ul>
                              <div className="store">{_col?.store}</div>
                              <div className="social">{_col?.social}</div>
                            </div>
                          </aside>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="copyrights">
          <div className="container">
            <div className="one_half">
              <b>{companyInfo.name}</b>
              <br />
               {translation('company',{taxId:companyInfo.taxId, time:companyInfo.lastUpdatedAt})}
              <br />
              Copyright © 2023
              <a href="/">{companyInfo.globalName}</a> - All rights reserved.
            </div>
            {/* <div className="one_half last aliright">
              <Link to="/policy">Terms of Service</Link>|<Link to="/privacy-policy">Privacy Policy</Link>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  )
}
