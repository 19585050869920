import React, { useMemo } from 'react'
import { PenaltyForm } from 'components/PenaltyForm/PenaltyForm'
import * as sc from './PenaltyCheckLayout.styled'
import { TableNav } from 'components/Widgets/TableNav'
import { QuickNav } from 'components/Widgets/QuickNav'
import { Category } from 'components/Category/Category'
import { FeedSmall } from 'components/Feed/FeedSmall'
import { PageLayout } from 'components/PageLayout/PageLayout'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'

export const PenaltyCheckLayout = (props) => {
  const { t: translation } = useTranslation()
  const { introduction } = props

  const renderTableNavInfo = useMemo(() => {
    if (!introduction || !introduction.categoriesNews || introduction.categoriesNews.length < 1) {
      return <Empty />
    }

    return <TableNav driver={introduction.categoriesNews[1]} />
  }, [introduction?.categoriesNews])
  const renderTableNavData = useMemo(() => {
    if (!introduction || !introduction.categoriesNews || introduction.categoriesNews.length < 2) {
      return <Empty />
    }

    return <TableNav driver={introduction.categoriesNews[2]} />
  }, [introduction?.categoriesNews])

  const renderMostViewed = useMemo(() => {
    if (!introduction || !introduction.mostReadNews || introduction.mostReadNews.length === 0) return <Empty />

    return introduction.mostReadNews.map((item, i) => (
      <React.Fragment key={item.stationNewsTitle}>
        <FeedSmall withoutDivider={i + 1 === introduction.mostReadNews.length} driver={item} />
      </React.Fragment>
    ))
  })

  return (
    <sc.Container>
      <PageLayout>
        <sc.Content>
          <sc.Left>
            <PenaltyForm />
          </sc.Left>
          <sc.Right>
            <QuickNav driver={props.setting} />
            <Category name={translation('category.most-viewed')} marginBottom={20} />
            {renderMostViewed}

            <sc.TableNavContainer>{renderTableNavInfo}</sc.TableNavContainer>
            <sc.TableNavContainer>{renderTableNavData}</sc.TableNavContainer>
          </sc.Right>
        </sc.Content>
      </PageLayout>
    </sc.Container>
  )
}
