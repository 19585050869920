import React, { useState } from 'react'
import { ReactComponent as LogoIcon } from '../../assets/new-icons/Logo3.svg'
import { ReactComponent as LogoDKON } from '../../assets/Theme/DKON/logoDKON.svg'
export const CheckLogoTheme=({width=35,height=34,style}) => {
  const appTheme=process.env.REACT_APP_THEME_NAME
  return(
    <>
      {appTheme == 'DKON' ? (
        <LogoDKON width={width} height={height} style={style}></LogoDKON>
      ):
      (
        <LogoIcon style={{marginLeft:'5px',padding: "5px 0"}} />
      )}
    </>
  )
}