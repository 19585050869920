import { PageLayout } from 'components/PageLayout/PageLayout'
import React from 'react'
import * as sc from './../FeedDetail.styled'
import { convertTextToHTML } from 'helper/common'
import { formatDate } from 'helper/formatDate'
import ResponsiveEmbed from 'react-responsive-embed'
import './../../../assets/scss/typography.scss'
export const RecruitmentFeedDetailView = ({detailPost,showModal=()=>{}}) => {
  const isEmbeddedCode = detailPost.embeddedCode?.includes('<iframe')
  return (
    <>
      <PageLayout>
        <sc.Content>
          <sc.Left>
            <sc.FeedWrapper>
              <sc.FeedDetailContainer>
                <sc.FeedContent id="popupContent">
                  <sc.Image bg={detailPost?.stationNewsAvatar || ''} />
                  <div className="out-sidehme">
                    <sc.styleNew>
                      <sc.Title className="title-large">{detailPost?.stationNewsTitle}</sc.Title>
                      <sc.Author className="text-small">
                        <div>
                          <div>{detailPost?.totalViewed} lượt xem</div>
                          <div>Đăng vào ngày {formatDate(detailPost.createdAt)}</div>
                        </div>
                        <div>
                           <sc.Share onClick={() =>showModal()}>Chia sẻ</sc.Share>
                        </div>
                      </sc.Author>
                      <sc.DividerSmall />

                      <sc.Description
                        className="text-small"
                        dangerouslySetInnerHTML={{
                          __html: convertTextToHTML(detailPost?.stationNewsContent)
                        }}
                      />
                      {detailPost.embeddedCode && isEmbeddedCode && (
                        <sc.IFrameResponsive>
                          <ResponsiveEmbed src={getVideoSrc(detailPost.embeddedCode)} allowFullScreen /> :
                        </sc.IFrameResponsive>
                      )}
                      {detailPost.embeddedCode && !isEmbeddedCode && (
                        <div dangerouslySetInnerHTML={{ __html: detailPost.embeddedCode }} className="mt-1 mb-2" />
                      )}
                    </sc.styleNew>
                  </div>
                </sc.FeedContent>
              </sc.FeedDetailContainer>
              <sc.Divider />
            </sc.FeedWrapper>
          </sc.Left>
        </sc.Content>
      </PageLayout>
    </>
  )
}

const getVideoSrc = (embeddedCode) => {
  const matches = embeddedCode.match(/src="([^"]*)"/i)
  return matches && matches[1]
}
