import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import SlickSlider from 'react-slick'
import * as sc from './Slider.styled'

export const Slider = (props) => {
  const settings = {
    swipeToSlide: true,
    variableWidth: true,
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 3000,
    appendDots: (dots) => {
      return <sc.Dot data-cy="Dot">{dots}</sc.Dot>
    }
  }

  const { driver } = props

  const renderSlider = useMemo(() => {
    if (!Array.isArray(driver.data)) return <></>

    return (
      <SlickSlider {...settings}>
        {driver.data.slice(0, 3).map((item) => {
          return (
            <sc.Thumbnail key={`slider-${item.stationNewsId}`} bg={item.stationNewsAvatar || ''}>
              <sc.Title>
                <Link style={{ textDecoration: 'none' }} to={`/detail-post/${item.stationNewsId}`}>
                  {item.stationNewsTitle}
                </Link>
              </sc.Title>
              <sc.Overlay />
            </sc.Thumbnail>
          )
        })}
      </SlickSlider>
    )
  }, [driver.data])

  return <sc.Container>{renderSlider}</sc.Container>
}
