import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { TheHeader } from '../TheHeader/TheHeader'
import { TheHeaderSearch } from '../TheHeader/TheHeaderSearch'
import { TheFooter } from 'components/TheFooter/TheFooter'
import { TheHeaderNavbar } from 'components/TheHeader/TheHeaderNavbar'
import _ from 'lodash'
import { Modal, Result, message } from 'antd'
import { useDispatch } from 'react-redux'
import LoginService from 'services/loginService'
// import zmpApis from 'zmp-sdk'
import swal from 'sweetalert'
import { LOGIN_ERROR } from 'constants/errorMessage'
import { handleLogin, handleUpdateDetail } from 'actions'
import { IS_ZALO_MINI_APP } from 'constants/global'
import { useLocation } from 'react-router-dom'
import { setMetaData } from 'actions'
import CommonService from 'services/commonService'
import addKeyLocalStorage from 'helper/localStorage'
import { routes } from 'App'
import BannerFooter from 'components/Banner/BannerFooter'
import HelloBanner from 'components/Banner/HelloBanner'

function LayoutPage(props) {
  const { Component, history } = props
  const isDividerFullWith = ['/stations', '/penalty-check'].includes(props.location.pathname)
  const auth = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  const [hided, setHided] = useState(true)
  const _handleLogin = (result) => {
    const { isSuccess, data, error } = result
    const isError = !isSuccess
    if (isError) {
      if (Object.keys(LOGIN_ERROR).includes(error)) {
        return swal(LOGIN_ERROR[error], {
          icon: 'warning'
        })
      } else {
        return swal('Đăng nhập thất bại', {
          icon: 'warning'
        })
      }
    } else {
      dispatch(handleLogin(data))

      // zmpApis.getUserInfo({
      //   success: (zaloUserData) => {
      //     // xử lý khi gọi api thành công
      //     const { userInfo } = zaloUserData
      //     const updateData = {}
      //     if (!data.firstName) {
      //       updateData.firstName = userInfo.name
      //     }

      //     if (!data.zaloId) {
      //       updateData.zaloId = userInfo.id
      //     }

      //     if (!_.isEmpty(updateData)) {
      //       LoginService.customUpdateInformation(
      //         {
      //           id: data.appUserId,
      //           data: updateData
      //         },
      //         data.token
      //       )
      //         .then(() => {
      //           dispatch(handleUpdateDetail(updateData))
      //         })
      //         .catch((err) => {
      //           console.log('updateInformation', err)
      //         })
      //     }

      //     if (!userInfo.idByOA) {
      //       zmpApis.followOA({
      //         id: process.env.REACT_APP_ZOA_ID,
      //         success: () => {
      //           dispatch(
      //             handleUpdateDetail({
      //               idByOA: process.env.REACT_APP_ZOA_ID
      //             })
      //           )
      //           message.success('Theo dõi thành công')
      //         }
      //       })
      //     } else {
      //       dispatch(
      //         handleUpdateDetail({
      //           idByOA: process.env.REACT_APP_ZOA_ID
      //         })
      //       )
      //     }
      //     history.push('/booking')
      //   },
      //   fail: (err) => {
      //     console.log('getUserInfo', err)
      //   }
      // })
    }
  }

  const getPhoneNumberByToken = (code, accessToken) => {
    // gọi API Server của bạn để truy xuất thông tin từ token và user access token
    LoginService.loginByZaloMiniApp({
      code: code,
      accessToken: accessToken
    })
      .then(_handleLogin)
      .catch((err) => {
        console.log('getPhoneNumberByToken', err)
        message.error('Đăng nhập với Zalo thất bại')
        setTimeout(() => {
          localStorage.clear()
          window.location.reload()
        }, 500)
      })
  }

  const _requestOpenSetting = () =>
    Modal.confirm({
      icon: <></>,
      content: <Result title="TTDK Đặt lịch đăng kiểm cần bạn cung cấp số điện thoại để tiếp tục sử dụng ứng dụng." />,
      cancelText: 'Hủy',
      okText: 'Xác nhận',
      onOk: () => {
        // zmpApis.openPermissionSetting()
      },
      onCancel: () => window.location.reload(),
      title: 'Yêu cầu quyền sử dụng số điện thoại'
    })

  const loginByZalo = () => {
    // zmpApis
    //   .login()
    //   .then((accessToken) => {
    //     zmpApis.getPhoneNumber({
    //       success: (data) => getPhoneNumberByToken(data.token, accessToken),
    //       fail: (error) => {
    //         console.log('getPhoneNumber', error)
    //         _requestOpenSetting()
    //       }
    //     })
    //   })
    //   .catch((err) => {
    //     _requestOpenSetting()
    //   })
  }

  const VALUE_PAGE_HIDE_FOOTER = 'chat-detail'

  const location = useLocation()
  const currentUrl = location.pathname

  useEffect(() => {
    if (!auth.isUserLoggedIn && IS_ZALO_MINI_APP) {
      loginByZalo()
    }
    const decodedPhoneNum = localStorage.getItem(addKeyLocalStorage('autoLoginPhone'))
    const decodedPass = localStorage.getItem(addKeyLocalStorage('autoLoginPass'))
    const isStaffAccount = localStorage.getItem(addKeyLocalStorage('isStaffAccount'))
    if(decodedPhoneNum && decodedPass && isStaffAccount && window.location.pathname !== "/autoLogout"){
      history.push(routes.login.path)
    }
  }, [])

  useEffect(() => {
    if (auth.isUserLoggedIn) {
      LoginService.getDetailUserById({ id : auth.data.appUserId }).then((res) => {
        dispatch(handleUpdateDetail(res.data))
      })
    }
    fetchMetaData()
  } , []);

  useEffect(() => {
    if (currentUrl.split('/')[1] === VALUE_PAGE_HIDE_FOOTER) {
      return setHided(false)
    } else return setHided(true)
  }, [currentUrl])

  function fetchMetaData() {
    CommonService.GetMetaData({}).then(result => {
      if (result)
      dispatch(setMetaData(result.data))
    })
  }

  return (
    <>
      <TheHeader
        renderSearch={() => <TheHeaderSearch {...props} />}
        renderTheNavbar={() => <TheHeaderNavbar isDividerFullWith={isDividerFullWith} current={props.location.pathname} {...props} />}
        {...props}
      />
      <Component {...props} />
      <BannerFooter/>
      <HelloBanner />
      {hided && <TheFooter {...props} />}
    </>
  )
}

const mapStateToProps = (state) => ({
  introduction: state.introduction,
  setting: state.setting,
  hotnews: state.hotnews
})

export default connect(mapStateToProps, null)(LayoutPage)
