import React from 'react'

export const QuickNav = (props) => {
  const landingPageUrl = (() => {
    if (!props.driver?.stationUrl) {
      return '#'
    }

    if (!props.driver.stationUrl.startsWith('http://')) {
      return `http://${props.driver.stationUrl}`
    }

    return props.driver.stationUrl
  })()

  return (
    <></>
    // <sc.Container marginBottom={props.marginBottom || 44}>
    //   <sc.Content className="flex-center">
    //     <sc.Top className="flex-center">
    //       <sc.Thumbnail bg={Logo} />
    //       <sc.Text>Cổng thông tin TTDK</sc.Text>
    //     </sc.Top>

    //     <sc.Bottom>
    //       <Link to="/stations">
    //         <sc.LinkItem>
    //           <sc.Icon bg={Arrow} />
    //           <sc.Title>Danh sách trạm đăng kiểm</sc.Title>
    //         </sc.LinkItem>
    //       </Link>
    //       <a href={landingPageUrl} target={"_blank"}>
    //         <sc.LinkItem>
    //           <sc.Icon bg={Arrow} />
    //           <sc.Title>Đăng ký kiểm định</sc.Title>
    //         </sc.LinkItem>
    //       </a>
    //       <Link to="/penalty-check">
    //         <sc.LinkItem>
    //           <sc.Icon bg={Arrow} />
    //           <sc.Title>Tra cứu phạt nguội</sc.Title>
    //         </sc.LinkItem>
    //       </Link>
    //        <Link to="/penalty-check">
    //         <sc.LinkItem>
    //           <sc.Icon bg={Arrow} />
    //           <sc.Title>Đặt lịch</sc.Title>
    //         </sc.LinkItem>
    //       </Link>
    //     </sc.Bottom>
    //   </sc.Content>
    // </sc.Container>
  )
}
