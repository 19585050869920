import { tablet } from 'constants/breakpoints'
import { ghostWhite } from 'constants/colors'
import { blackRaisin } from 'constants/colors'
import { blueSapphire } from 'constants/colors'
import { grayGainsboro } from 'constants/colors'
import { redLust } from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 316px;
  display: flex;
  flex-flow: column;

  @media (max-width: ${tablet}) {
    margin-top: 24px;
  }

  a:hover {
    color: ${blueSapphire};
  }
`

export const Content = styled.div`
  padding: 0 16px;
  border-left: 1px solid ${grayGainsboro};
  border-right: 1px solid ${grayGainsboro};
  border-bottom: 1px solid ${grayGainsboro};

  > a {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    color: ${blackRaisin};

    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    &:hover {
      color: ${blueSapphire};
    }

    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      margin-top: 8px;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      min-width: 7px;
      min-height: 7px;
      border-radius: 50%;
      margin-right: 12px;
      background: ${redLust};
    }
  }
`

export const HeaderLine = styled.div`
  height: 4px;
  width: 100%;

  background: ${redLust};
  border-radius: 12px 12px 0px 0px;
`

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: ${blackRaisin};
`

export const Header = styled(Text)`
  width: 316px;
  height: 58px;
  background: ${ghostWhite};

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;

  text-transform: uppercase;
  border-bottom: 1px solid ${grayGainsboro};
  border-left: 1px solid ${grayGainsboro};
  border-right: 1px solid ${grayGainsboro};

  > a {
    color: ${blackRaisin};
  }
`
