import React from 'react'
import * as sc from './TheHeader.styled'
import Search from '../../assets/icons/search.svg'
import { useTranslation } from 'react-i18next'

export const TheHeaderSearch = () => {
  const { t: translation } = useTranslation()

  return (
    <sc.SearchContainer>
      <sc.SearchInput placeholder={translation('landing.search')} />
      <sc.IconSquare url={Search} />
    </sc.SearchContainer>
  )
}
