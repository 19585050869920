import { tablet } from 'constants/breakpoints'
import { divider } from 'constants/colors'
import { blueSapphire } from 'constants/colors'
import { blackRaisin } from 'constants/colors'
import { black } from 'constants/colors'
import { grayGainsboro } from 'constants/colors'
import { redLust } from 'constants/colors'
import { cultured } from 'constants/colors'
import { sonicSilver } from 'constants/colors'
import { white } from 'constants/colors'

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 99;
`
export const Content = styled.div`
  /* width: 629px; */
  height: 54px;

  justify-content: center;

  @media (max-width: 994px) {
    padding: 0 16px;
  }
`
export const LogoImg = styled.img`
  height: 54px;

  justify-content: center;
  
  @media (max-width: 994px) {
    padding: 0 16px;
  }
`
export const Link = styled.span`
  display: inline-block;
  color: ${blackRaisin};
  white-space: nowrap;

  &:hover {
    color: ${blueSapphire};
    text-decoration: none;
  }

  .active {
    color: ${blueSapphire};
  }
`

export const Item = styled(Link)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: ${blackRaisin};

  text-transform: uppercase;

  padding: 16px;

  &:hover,
  &.active {
    color: ${blueSapphire};
  }
`

export const IconContainer = styled.div`
  background: #f9f9f9;
  width: 44px;
  height: 44px;
`
export const Icon = styled(Link)`
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;

  background: url(${(props) => props.bg}) center center no-repeat;
  background-size: 100%;
`
export const Divider = styled.div`
  width: 0px;
  height: 20px;

  border: 1px solid ${divider};

  @media (max-width: ${tablet}) {
    display: none;
  }
`

export const HorizontalDivider = styled.div`
  height: 3px;

  background: ${blueSapphire};
  border-radius: 0px 0px 40px 40px;

  ${(props) =>
    props.isDividerFullWith &&
    `
        position: absolute;
        width: 100%;
        z-index: 10;
        left: 0;
  `};
`

export const NavItemDesktop = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 994px) {
    display: none;
  }
`

export const NavItemMobileItemWrapper = styled.div`
  margin-left: auto;
  height: 64px;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;

  position: absolute;
  right: 0;
  top: 38px;

  background: ${white};
  filter: drop-shadow(rgba(0, 0, 0, 0.07) 0px 4px 3px) drop-shadow(rgba(0, 0, 0, 0.06) 0px 2px 2px);
  height: fit-content;
  display: none;
  @media (max-width: 994px) {
    &.open {
      display: flex;
    }
  }
`

export const HamburgerItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: #000;
  transform: rotate(0);
  transition: all 0.5s;
`

export const HamburgerTop = styled(HamburgerItem)``

export const HamburgerMiddle = styled(HamburgerItem)`
  transform: translateY(7px);
`

export const HamburgerBottom = styled(HamburgerItem)`
  transform: translateY(14px);
`

export const Hamburger = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;

  &.open {
    transform: rotate(90deg);
    transform: translateX(0);

    ${HamburgerTop} {
      transform: rotate(45deg) translateX(6px) translateY(6px);
    }

    ${HamburgerMiddle} {
      display: none;
    }

    ${HamburgerBottom} {
      transform: rotate(-45deg) translateX(-6px) translateY(6px);
    }
  }
`

export const NavItem = styled.a`
  display: flex;

  height: 40px;
  min-width: 40px;
  max-height: 40px;
  padding: 12px;

  border: 1px solid ${grayGainsboro};
  border-radius: 8px;

  color: ${white};

  &:hover {
    color: ${white};
    text-decoration: none;
  }

  @media (max-width: 994px) {
    border: none;
    padding: 0;
  }
`

export const IconPhone = styled(Icon)`
  height: 17.16px;
`
export const IconSquare = styled(Icon)`
  height: 17.88px;
  min-width: 17.88px;
`

export const NavContent = styled.span`
  display: inline-block;
  margin-left: 6px;

  @media (max-width: 994px) {
    color: ${redLust};
  }
`

export const NavItemMobileContainer = styled.div`
  position: relative;
  display: none;
  margin-left: auto;
  > button {
    outline: none;
    border: none;
    background-color: transparent;
    width: 28px;
    height: 16px;
  }

  @media (max-width: 994px) {
    display: block;
  }
`

export const NavExternalItem = styled.div`
  position: absolute;
  top: 50px;

  display: none;
  flex-flow: column;
  align-items: flex-start;
  background: ${white};

  &:hover {
    display: flex;
  }

  @media (max-width: 994px) {
    display: flex;
    position: relative;
    top: unset;
    background-color: transparent;
    margin-left: 24px;
    ${Item} {
      font-size: 13px;
    }
  }

  &.close {
    display: none !important;
  }
`

export const CategoryList = styled.div`
  &:hover {
    ${NavExternalItem} {
      display: flex;
    }
  }
`
