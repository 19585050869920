import { black } from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-end;

  margin-bottom: ${(props) => props.marginBottom}px;
`

export const BreadcrumbItem = styled.span`
  font-style: normal;

  text-decoration: none;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: rgba(117, 117, 117, 0.5);
  display: inline-block;

  &.active > a {
    color: ${black};
  }

  > a {
    color: rgba(117, 117, 117, 0.5);
    display: inline-block;
  }
`

export const Slash = styled(BreadcrumbItem)`
  margin: 0 4px;
  font-size: 10px;
`
