const initialState = {
  total: 0
}

export default function TotalVehiclereducer(state = initialState, action) {
  switch (action.type) {
    case 'ASSIGNING':
      return {
        ...state,
        total: action.data
      }
    default:
      return state
  }
}
