import { INIT, UPDATE } from '../constants/introduction'
import addKeyLocalStorage from 'helper/localStorage'
let initialState = {
  isLoading: true,
  generalNews: {},
  categoriesNews: []
}
const data = window.localStorage.getItem(addKeyLocalStorage('introduction'))
if (data && data.length) {
  const newData = JSON.parse(data)
  initialState = {
    isLoading: true,
    ...initialState,
    ...newData
  }
}

export default function introductionReducer(state = initialState, action) {
  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...action.data,
        generalNews: action?.data || [],
        isLoading: false
      }
    }
    case UPDATE: {
      window.localStorage.setItem(
        addKeyLocalStorage('introduction'),
        JSON.stringify({
          ...state,
          ...action.data
        })
      )
      return {
        ...state,
        ...action.data
      }
    }
    default:
      return state
  }
}
