import * as actionTypes from '../constants/member'

const handleSignin = (data) => ({
  type: actionTypes.USER_LOGIN,
  data: data
})

const handleSignout = () => ({
  type: actionTypes.USER_RESET
})

const handleLogin = (data) => ({
  type: 'LOGIN',
  data: data
})

const handleUpdateDetail = (data) => ({
  type: 'USER_DETAILS_UPDATE',
  data: data
})

const handleLogout = () => ({
  type: 'LOGOUT'
})

export { handleSignin, handleSignout, handleLogin, handleLogout, handleUpdateDetail }
