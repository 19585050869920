import { STATIONS_TYPE } from './stationsList'

export const ACTION_BANNER = {
    FETCH_BANNERS_SUCCESS: "FETCH_BANNERS_SUCCESS"
}

export const BANNER_LAYOUT = {
    HOME_BOTTOM: "HOME_BOTTOM",
    SCHEDULED_DETAIL: "SCHEDULED_DETAIL",
    BOOKING_SUCCESS: "BOOKING_SUCCESS",
    HELLO_SCREEN: "HELLO_SCREEN",
    ADS_BANNER:"ADS_BANNER",
    MAIN_BANNER:"MAIN_BANNER",

}

//Định nghĩa banner section
const BANNER_SECTION = {
    HOMEPAGE_MAIN_BANNER: 10, // Banner chính trang chủ trang chủ
    HOMEPAGE_HOT_BANNER: 11, // Banner nổi bật trên trang chủ
    HOMEPAGE_BOTTOM_BANNER: 12, // Banner trang chủ dưới
    WELCOME_SCREEN: 13, // Banner màn hình chào
    DETAIL_SCHEDULE: 14, // Banner chi tiết lịch hẹn
    SUCCESSFUL_APPOINTMENT: 15, // Banner đặt lịch thành công
    // Từ 1001 - 1999 => Dùng cho các "Điểm dịch vụ"
    EXTERNAL: 1001, //Trung tâm đăng kiểm
    INTERNAL: 1002, //Nội bộ TTDK
    GARAGE: 1003, //Garage
    HELPSERVICE: 1004, //cứu hộ
    INSURANCE: 1005, // Đơn vị Bảo hiểm
    CONSULTING: 1006, // Đơn vị tư vấn
    AFFILIATE: 1007, // Đơn vị liên kết
    ADVERTISING: 1008, // Đơn vị quảng cáo
    COOPERATIVE: 1009, // Hợp tác xã
    USED_VEHICLES_DEALERSHIP: 1010, // Đơn vị mua bán xe cũ
    SPARE_PARTS_DEALERSHIP: 1011, // Mua bán phụ tùng ô tô
    PARKING_LOT: 1012, // Bãi giữ xe
    VEHICLE_MODIFICATION: 1013, // Đơn vị cải tạo xe
    DRIVING_SCHOOL: 1014, // Trường học lái xe
    CHAUFFEUR_SERVICE: 1015, // Dịch vụ lái xe hộ
    PARTS_MANUFACTURING_CONSULTANCY: 1016, // Tư vấn sản xuất phụ tùng xe
    DRIVER_HEALTH: 1017, //  Khám sức khoẻ lái xe
    CAR_EVALUATION_SERVICE: 1018, // Dịch vụ định giá xe
  }

// Các loại banner xuất hiện trên web ttdk
export const BANNER_TYPE = {
    //map banner-layout voi banner
    [BANNER_LAYOUT.MAIN_BANNER]: BANNER_SECTION.HOMEPAGE_MAIN_BANNER,
    [BANNER_LAYOUT.ADS_BANNER]: BANNER_SECTION.HOMEPAGE_HOT_BANNER,
    [BANNER_LAYOUT.HOME_BOTTOM]: BANNER_SECTION.HOMEPAGE_BOTTOM_BANNER,
    [BANNER_LAYOUT.HELLO_SCREEN]: BANNER_SECTION.WELCOME_SCREEN,
    [BANNER_LAYOUT.SCHEDULED_DETAIL]: BANNER_SECTION.DETAIL_SCHEDULE,
    [BANNER_LAYOUT.BOOKING_SUCCESS]: BANNER_SECTION.SUCCESSFUL_APPOINTMENT,

    // Tiện ích
    // map các điểm dịch vụ với banner
    [STATIONS_TYPE.CENTER]: BANNER_SECTION.EXTERNAL,
    [STATIONS_TYPE.INSPECTION_RESCUE]: BANNER_SECTION.HELPSERVICE,
    [STATIONS_TYPE.TRANSPORT_COOPERATIVE]: BANNER_SECTION.COOPERATIVE,
    [STATIONS_TYPE.USED_CAR_TRADING]: BANNER_SECTION.USED_VEHICLES_DEALERSHIP,
    [STATIONS_TYPE.INSURANCE_COMPANY]: BANNER_SECTION.INSURANCE,
    [STATIONS_TYPE.VEHICLE_RENOVATION_COMPANY]: BANNER_SECTION.VEHICLE_MODIFICATION,
    //Các điểm dịch vụ đang đóng
    [STATIONS_TYPE.GARAGE]: BANNER_SECTION.GARAGE,
    [STATIONS_TYPE.SPARE_PARTS_DEALERSHIP]: BANNER_SECTION.SPARE_PARTS_DEALERSHIP,
    [STATIONS_TYPE.PARKING_LOT]: BANNER_SECTION.PARKING_LOT,
    [STATIONS_TYPE.DRIVING_SCHOOL]: BANNER_SECTION.DRIVING_SCHOOL,
    [STATIONS_TYPE.CHAUFFEUR_SERVICE]: BANNER_SECTION.CHAUFFEUR_SERVICE,
    [STATIONS_TYPE.PARTS_MANUFACTURING_CONSULTANCY]: BANNER_SECTION.PARTS_MANUFACTURING_CONSULTANCY,
    [STATIONS_TYPE.INTERNAL]: BANNER_SECTION.INTERNAL,
    [STATIONS_TYPE.CONSULTING]: BANNER_SECTION.CONSULTING,
    [STATIONS_TYPE.AFFILIATE]: BANNER_SECTION.AFFILIATE,
    [STATIONS_TYPE.ADVERTISING]: BANNER_SECTION.ADVERTISING,
    [STATIONS_TYPE.DRIVER_HEALTH]: BANNER_SECTION.DRIVER_HEALTH,
    [STATIONS_TYPE.CAR_EVALUATION_SERVICE]: BANNER_SECTION.CAR_EVALUATION_SERVICE,

}