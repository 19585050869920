import React, { useEffect, useState, Suspense } from 'react'
import './../../assets/scss/typography.scss'
import { Modal } from 'antd'
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
export const FeedDetailSharing = ({shareUrl,isModalOpen,detailPost,handleOk,handleCancel}) => {
return(
  <>
    <Modal title="Chia sẻ" style={{maxWidth:'280px',width:'100%'}} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{justifyContent:'center',display:'flex',gap:'15px'}}>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon onClick={()=>handleCancel()} size={40} round={true}></FacebookIcon>
        </FacebookShareButton>
        <EmailShareButton url={shareUrl} subject={detailPost?.stationNewsTitle}>
          <EmailIcon onClick={()=>handleCancel()} size={40} round={true}></EmailIcon>
        </EmailShareButton>
        <TelegramShareButton url={shareUrl}>
          <TelegramIcon onClick={()=>handleCancel()} size={40} round={true}></TelegramIcon>
        </TelegramShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon onClick={()=>handleCancel()} size={40} round={true}></TwitterIcon>
        </TwitterShareButton>
      </div>
    </Modal>
  </>
)
}
