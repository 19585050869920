import { tablet } from 'constants/breakpoints'
import { blueSapphire } from 'constants/colors'
import { cultured } from 'constants/colors'
import { blackRaisin } from 'constants/colors'
import styled from 'styled-components'

export const Content = styled.div`
  align-items: flex-start;
`

export const Thumbnail = styled.div`
  width: 116px;
  height: 77px;
  max-width: 116px;
  max-height: 77px;
  min-width: 116px;
  min-height: 77px;
  margin-right: 12px;

  background: url(${(props) => props.bg}) center center no-repeat;
  background-color: ${cultured};
  background-size: cover;

  @media (max-width: ${tablet}) {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`

export const Info = styled.div``

export const Title = styled.div`
  color: ${blackRaisin};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
`

export const Divider = styled.div`
  width: 316px;
  height: 0px;
  margin: 12px 0;

  border: 1px solid rgba(0, 0, 0, 0.08);

  @media (max-width: ${tablet}) {
    width: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 316px;
  height: 77px;

  flex: none;
  order: 0;
  flex-grow: 0;

  &:hover {
    ${Title} {
      color: ${blueSapphire};
    }
  }

  @media (max-width: ${tablet}) {
    width: 100%;
  }
`
