import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

export const ReCaptcha = (props) => {
  return (
    // there no way styling recaptcha, so we stick with the default style
    // https://stackoverflow.com/questions/27713505/recaptcha-api-v2-styling
    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={props.onChange} />
  )
}
