import { ZALO_HOST } from 'constants/url'
import Request from './request'

export default class LoginService {
  static async getDetailByUrl() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/getDetailByUrl',
        data: {
          stationsUrl: window.origin.split('://')[1]
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getDetailUserById(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/getDetailInfo',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getLandingPageInfo() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationIntroduction/stationIntroductionDetail',
        data: {
          stationUrl: window.origin.split('://')[1]
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve(null)
        }
      })
    })
  }

  static async getDetailPunish(data = {}) {
    const result = await Request.send({
      method: 'POST',
      path: '/CustomerCriminalRecord/user/fetchCrimeRecords',
      data
    })

    return result.data
  }

  static async login(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/loginUserByPhone',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async uploadAvatar(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Upload/uploadUserAvatar',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async updateInformation(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/updateInfoUser',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async customUpdateInformation(data, customToken) {
    return new Promise((resolve) => {
      Request.customSend({
        method: 'POST',
        path: '/AppUsers/user/updateInfoUser',
        data: data,
        customToken: customToken
      }).then((result = {}) => {
        const { statusCode, data, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async updatePassword(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/changePasswordUser',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async resetPassword(data, token) {
    return new Promise((resolve) => {
      Request.customSend({
        method: 'POST',
        path: '/AppUsers/user/resetPasswordUserByToken',
        data: data,
        query: null,
        headers: {},
        newUrl: null,
        customToken: token
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async register(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/registerUserByPhone',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async registerEnterpriseAccount(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/registerEnterpriseAccount',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async deleteAccount() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/deleteAccount',
        data: {}
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async userRequestEmailOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/OTPMessage/user/requestEmailOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async requestPhoneOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/OTPMessage/user/requestPhoneOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async resetPasswordByOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/resetPasswordByOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }
  
  static async activeUserByOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/activeUserByOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async resetPasswordByEmail(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/resetPasswordByEmail',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async requestPhoneOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/OTPMessage/user/requestPhoneOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async verifyOtp(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/OTPMessage/user/confirmPhoneOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async requestZaloOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/OTPMessage/user/requestZaloOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async requestPhoneCallOTP(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/OTPMessage/user/requestPhoneCallOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async verifyZaloOTP(data) {
    return new Promise((resolve, reject) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/resetPasswordByZaloOTP',
        data: data
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async getHomeNotification(data = {}) {
    const filter = {
      limit: 100,
      skip: 0,
      filter: {
        appUserId: data?.id
      }
    }

    const vihcles = await Request.send({
      method: 'POST',
      path: '/AppUserVehicle/user/getList',
      data: filter
    })

    const sched = await Request.send({
      method: 'POST',
      path: '/CustomerSchedule/user/getListSchedule',
      data: {
        limit: 100,
        skip: 0
      }
    })

    return {
      vehicleList: vihcles?.data?.data,
      scheds: sched?.data?.data || []
    }
  }
  static async refreshTokenUser(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/AppUsers/user/refreshToken',
        data: data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async loginByZaloMiniApp(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        newUrl: `${ZALO_HOST}/AppUsers/zalo/loginByZApp`,
        data: data
      }).then((result = {}) => {
        const { statusCode, data, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }
}
