import { Empty } from 'antd'
import { formatDate } from 'helper/formatDate'
import React from 'react'
import { Link } from 'react-router-dom'
import * as sc from './Feed.styled'
import { RATIO_IMG } from 'constants/global'

export const Feed = (props) => {
  const { driver,path='/detail-post' } = props

  return (
    <>
      {!driver ? (
        <Empty />
      ) : (
        <>
          <sc.Container className="pointer" type={props.type}>
            <Link style={{ textDecoration: 'none' }} to={`${path}/${driver.stationNewsId}`}>
              <sc.Content className="flex-center">
                <sc.Thumbnail ratio={RATIO_IMG.DEFAULT.value} bg={driver.stationNewsAvatar || ''} />
                <sc.Info>
                  <sc.Title className='title-small' title={driver.stationNewsTitle}>{driver.stationNewsTitle}</sc.Title>
                  {/* <sc.Description title={driver.stationNewsContent}>
                    <div dangerouslySetInnerHTML={{__html: driver.stationNewsContent }} />
                  </sc.Description> */}
                  <sc.CreatedAt>
                    <p>Ngày {formatDate(driver.createdAt || new Date())}</p>
                    {driver?.totalViewed} lượt xem
                  </sc.CreatedAt>
                </sc.Info>
              </sc.Content>
            </Link>
          </sc.Container>
          {!props.withoutDivider && <sc.Divider type={props.type} />}
        </>
      )}
    </>
  )
}
