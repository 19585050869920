import React, { useEffect, useRef, useState } from 'react'
import { Button, message, Modal, Radio, Rate, Space } from 'antd';
import "./index.scss"
export default function ReviewPopup({
    isBookingSuccess = false,
}) {
    //user đã review app chưa!
    const reviewed = localStorage.getItem('reviewed');
    const currentPath = window.location.pathname;
    const isHomePage = currentPath === "/"
    const [open, setOpen] = useState(reviewed ? false :isBookingSuccess);
    const result = navigator.userAgent.toLowerCase();
    const iPhone = result.indexOf("iphone") > -1;
    const android = result.indexOf("android") > -1;

    //Số lần vào app xuất hiện popup review (2-5)
    const getRandomVisitCount = () => {
        return Math.floor(Math.random() * 4) + 2;
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleClickReview = () => {
        localStorage.setItem('reviewed', true);
        setOpen(false);
    }

    const handleCheckOpenPopup =()=>{
        // Lưu random số lần bật popup review để popup quảng cáo không hiện nữa
        let randomCount = parseInt(localStorage.getItem('randomVisitCount') || 0);
        if(!randomCount){
            const randomNumber = getRandomVisitCount()
            randomCount = randomNumber
            localStorage.setItem('randomVisitCount', randomNumber);
        }
        if (!reviewed && isHomePage) {
            // Số lần user vào app
            const storedVisitCount = parseInt(localStorage.getItem('storedVisitCount') || 0);
            // Cập nhật số lần user vào app
            localStorage.setItem('storedVisitCount', storedVisitCount + 1);
            // Kiểm tra xem nếu user vào app bằng với số lần quy định thì sẽ mở popup
            if (storedVisitCount >= randomCount) {
                setOpen(true);
                localStorage.setItem('storedVisitCount', 0);
                localStorage.setItem('randomVisitCount', getRandomVisitCount());
            }
        }
    }

    useEffect(() => {
        handleCheckOpenPopup()
    }, [])

    const RenderButton = () => {
        if (iPhone)
            return (
                <Button
                    className="login__button df"
                    style={{ marginTop: 25 }}
                    href="https://apps.apple.com/vn/app/ttdk-%C4%91%E1%BA%B7t-l%E1%BB%8Bch-%C4%91%C4%83ng-ki%E1%BB%83m/id1663487095"
                    target="_blank"
                    type="primary"
                    onClick={handleClickReview}
                    size="large">
                    Đánh giá
                </Button>
            )
        else if (android) {
            return (
                <Button
                    className="login__button df"
                    style={{ marginTop: 25 }}
                    href="https://play.google.com/store/search?q=TTDK&c=apps&hl=vi-VN"
                    target="_blank"
                    onClick={handleClickReview}
                    type="primary"
                    size="large">
                    Đánh giá
                </Button>
            )
        }
        else return (
            <div>
                <Button
                    className="login__button df"
                    style={{ marginTop: 25 }}
                    href="https://apps.apple.com/vn/app/ttdk-%C4%91%E1%BA%B7t-l%E1%BB%8Bch-%C4%91%C4%83ng-ki%E1%BB%83m/id1663487095"
                    target="_blank"
                    type="primary"
                    onClick={handleClickReview}
                    size="large">
                    Đánh giá trên App Store
                </Button>
                <Button
                    className="login__button df"
                    style={{ marginTop: 25 }}
                    href="https://play.google.com/store/search?q=TTDK&c=apps&hl=vi-VN"
                    target="_blank"
                    type="primary"
                    onClick={handleClickReview}
                    size="large">
                    Đánh giá trên Google Play
                </Button>
            </div>
        )
    }
    return (
        <Modal
            onCancel={onClose}
            placement="top"
            width={600}
            height={600}
            footer={null}
            open={open}
        >
            <div className={`review-header sticky-header d-flex ai-c j-sb`} style={{ borderColor: 'transparent', height: '50px' }}>
                <div className="d-flex ai-c mg-auto">
                    <div style={{ color: 'white' }} className="title">
                        ĐÁNH GIÁ/GÓP Ý
                    </div>
                </div>
            </div>
            <div className='container-review'>
                <div style={{ marginTop: 10 }}>
                    <p>Cảm ơn bạn đã tin tưởng và sử dụng ứng dụng TTDK - Đặt lịch đăng kiểm. Hãy mô tả trải nghiệm / góp ý của bạn khi sử dụng ứng dụng TTDK</p>

                    <RenderButton />
                </div>
            </div>

        </Modal>

    )
}
