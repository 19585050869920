import React, { useState } from 'react'
import addKeyLocalStorage from '../../helper/localStorage'
export const ClickCountableArea=({link,children,target,Id,className}) => {
  const getCountClick = Number(localStorage.getItem(addKeyLocalStorage(`UpdateCountClickInterval_${Id}`)))
  const [countClick,setCountClick]=useState(getCountClick || 0)
  const handleCount=(Id)=>{
    setCountClick((countClick)=> countClick + 1)
    localStorage.setItem(addKeyLocalStorage(`UpdateCountClickInterval_${Id}`), countClick + 1)
  }
  return(
    <a className={className} href={link} target={target} onClick={()=>{handleCount(Id)}} >
      {children}
    </a>
  )
}