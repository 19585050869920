import React, { useEffect, useRef } from 'react'
import * as sc from './TheHeaderNavbar.styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'hooks/window-dimensions'
import { ReactComponent as NewHomeIcon } from '../../assets/icon3/home.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icon3/profile-circle.svg'
import { ReactComponent as NotificationIcon } from '../../assets/icon3/notification.svg'
import { ReactComponent as TransportationIcon } from '../../assets/icon3/schedule.svg'
import { ReactComponent as MenuIcon } from '../../assets/icon3/logo.svg'
import { routes } from 'App'
import { CheckLogoTheme } from 'components/BasicComponent/CheckLogoTheme'
import { ReactComponent as LogoDKON } from '../../assets/Theme/DKON/logoDKON.svg'

export const TheHeaderNavbar = (props) => {
  const { t: translation } = useTranslation()
  const { height, width } = useWindowDimensions()
  const appTheme=process.env.REACT_APP_THEME_NAME
  const onCloseMenu = () => {
    if (document.querySelector('#NavExternalItem')) {
      document.querySelector('#NavExternalItem').classList.add('close')
      setTimeout(() => {
        document.querySelector('#NavExternalItem').classList.remove('close')
      }, 100)
    }
  }
  const toggleMenu = () => {
    // document.querySelector("#menu-btn").classList.toggle("open");
    // document.querySelector("#mobile-header-content").classList.toggle("open");
    // onCloseMenu();
  }
  // useEffect(() => {
  //   document.querySelector("#menu-btn").addEventListener("click", (e) => {
  //     toggleMenu()
  //   });
  // }, []);

  const renderNavItems = () => {
    return (
      <React.Fragment>
        <sc.CategoryList className="relative category-list">
          <Link to={routes.home.path}>
            <sc.Item className={`${props.current.includes('/') && props.current.length == 1 && 'active'}`} onClick={toggleMenu}>
              {translation("header.Home")}
            </sc.Item>
          </Link>
        </sc.CategoryList>
        <sc.Divider />
        {/* <sc.Divider />
        <Link to={"/stations"}>
          <sc.Item
            className={`${props.current.includes("stations") && "active"}`}
            href={"/stations"}
          >
            {translation("header.stations-list")}
          </sc.Item>
        </Link>
        <sc.Divider /> */}
        {/* <Link to={"/penalty-check"}>
          <sc.Item
            className={`${props.current.includes("penalty-check") && "active"}`}
            href={"/penalty-check"}
          >
            {translation("header.punish")}
          </sc.Item>
        </Link> */}
        <Link to={routes.booking.path}>
          <sc.Item href={routes.booking.path} className={`${props.current.includes(routes.bookingHistory.path) ? '' : props.current.includes(routes.booking.path) && 'active'}`}>
            {translation('header.Booking')}
          </sc.Item>
        </Link>
        <sc.Divider />
        <Link to={routes.news.path}>
          <sc.Item href={routes.news.path} className={`${props.current.includes(routes.news.path) && 'active'}`}>
            {translation('header.News')}
          </sc.Item>
        </Link>
        <sc.Divider />
        <Link to={routes.bookingHistory.path}>
          <sc.Item href={routes.bookingHistory.path} className={`${props.current.includes(routes.bookingHistory.path) && 'active'}`}>
           {translation('header.Appointment_schedule')}
          </sc.Item>
        </Link>
        <sc.Divider />
        <Link to={routes.notification.path}>
          <sc.Item href={routes.notification.path} className={`${props.current.includes(routes.notification.path) && 'active'}`}>
            {translation('header.Notification')}
          </sc.Item>
        </Link>
        <sc.Divider />
        {/* <Link to={"/staffs"}>
          <sc.Item
            className={`${props.current.includes("staffs") && "active"}`}
            href={"/staffs"}
          >
            Nhân viên trạm
          </sc.Item>
        </Link>
        <sc.Divider />
        <Link to={"/cameras"}>
          <sc.Item
            className={`${props.current.includes("cameras") && "active"}`}
            href={"/cameras"}
          >
            Cameras
          </sc.Item>
        </Link> */}
        <Link to={'/profile'}>
          <sc.Item className={`${props.current.includes('#') && 'active'}`} href={'/profile'}>
            {translation('header.Account')}
          </sc.Item>
        </Link>
        <sc.Divider />
        <Link to={routes.contactCooperation.path}>
          <sc.Item href={routes.contactCooperation.path} className={`${props.current.includes(routes.contactCooperation.path) && 'active'}`}>
             {translation('header.Contact_for_cooperation')}
          </sc.Item>
        </Link>
      </React.Fragment>
    )
  }

  const MobileMenu = () => (
    <>
      <sc.Hamburger id="menu-btn" className="flex-center">
        <sc.HamburgerTop />
        <sc.HamburgerMiddle />
        <sc.HamburgerBottom />
      </sc.Hamburger>
      <sc.NavItemMobileItemWrapper id="mobile-header-content">
        <React.Fragment>
          <sc.CategoryList className="relative category-list">
            <Link>
              <sc.Item className={`${props.current.includes('new') && 'active'}`} onClick={onCloseMenu}>
                {translation('header.new')}
              </sc.Item>
            </Link>
          </sc.CategoryList>
          <Link to={'/stations'}>
            <sc.Item className={`${props.current.includes('stations') && 'active'}`} href={'/stations'}>
              {translation('header.stations-list')}
            </sc.Item>
          </Link>
          <Link to={'/penalty-check'}>
            <sc.Item className={`${props.current.includes('penalty-check') && 'active'}`} href={'/penalty-check'}>
              {translation('header.punish')}
            </sc.Item>
          </Link>
          <Link to={'/staffs'}>
            <sc.Item className={`${props.current.includes('staffs') && 'active'}`} href={'/staffs'}>
              {translation('header.Contact_for_cooperation')}
            </sc.Item>
          </Link>
          <Link to={'/cameras'}>
            <sc.Item className={`${props.current.includes('cameras') && 'active'}`} href={'/cameras'}>
              Cameras
            </sc.Item>
          </Link>
          <Link to={'/#'}>
            <sc.Item className={`${props.current.includes('#') && 'active'}`} href={'/#'}>
              {translation('Login')}
            </sc.Item>
          </Link>
        </React.Fragment>
      </sc.NavItemMobileItemWrapper>
    </>
  )

  return (
    <>
      <sc.Container>
        {width >= 994 && (
          <sc.Content className="flex-center">
            <Link to={'/'}>
              {<CheckLogoTheme width={35} height={34}/>}
            </Link>
            <sc.NavItemDesktop>{renderNavItems()}</sc.NavItemDesktop>
            <sc.NavItemMobileContainer>{/* <MobileMenu /> */}</sc.NavItemMobileContainer>
          </sc.Content>
        )}

        {width < 994 && !props.hideMobileMenu && (
          <div className="mb-content mobile-menu">
            <div className="d-flex ai-c j-sb w-100">
              <Link to={'/'} className={`menu-item ${props.current.includes('/') && props.current.length == 1 && 'active'}`}>
                <NewHomeIcon className="change-fill icon-nav" />
                <div className="mtitle">{translation('header.Home')}</div>
              </Link>
              {/* <Link to={routes.booking.path} className={`menu-item ${props.current.includes(routes.booking.path) && 'active'}`}>
                <CalendarIcon />
                <div className="mtitle">Đặt lịch</div>
              </Link> */}
              <Link to={routes.bookingHistory.path} className={`menu-item ${props.current.includes(routes.bookingHistory.path) && 'active'}`}>
                <TransportationIcon className="change-stroke icon-nav" />
                <div className="mtitle">{translation('header.Appointment_schedule')}</div>
              </Link>
              <Link to={'/booking'} className="middle-item po-r">
                <div className="po-a abs-item">
                  {appTheme == 'DKON' ? (
                    <LogoDKON width={80} height={80}></LogoDKON>
                  ):
                  (
                    <MenuIcon />
                  )}
                </div>
              </Link>
              <Link to={routes.notification.path} className={`menu-item ${props.current.includes(routes.notification.path) && 'active'}`}>
                <NotificationIcon className="change-stroke icon-nav" />
                <div className="mtitle">{translation('header.Notification')}</div>
              </Link>
              <Link to={'/profile'} className={`menu-item ${props.current.includes('/profile') && 'active'}`}>
                <ProfileIcon className="change-stroke icon-nav" />
                <div className="mtitle">{translation('header.Account')}</div>
              </Link>
            </div>
          </div>
        )}
      </sc.Container>
      {/* <sc.HorizontalDivider isDividerFullWith={props.isDividerFullWith} /> */}
    </>
  )
}
