import addKeyLocalStorage from 'helper/localStorage'

const initialState = {
  isUserLoggedIn: !!window.localStorage.getItem(addKeyLocalStorage('isUserLoggedIn')),
  data: JSON.parse(window.localStorage.getItem(addKeyLocalStorage('data')) || '{}'),
  isInitLoad: true
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      if (action.data) {
        window.localStorage.setItem(addKeyLocalStorage('isUserLoggedIn'), true)
        window.localStorage.setItem(addKeyLocalStorage('data'), JSON.stringify(action.data))
        return {
          ...state,
          data: action.data,
          isUserLoggedIn: true
        }
      }
    case 'USER_DETAILS_UPDATE': {
      if (action.data) {
        const data = {
          ...state.data,
          ...action.data
        }
        window.localStorage.setItem(addKeyLocalStorage('data'), JSON.stringify(data))
        return {
          ...state,
          data: data,
          isUserLoggedIn: true
        }
      }
      return {}
    }
    case 'LOGOUT':
      window.localStorage.removeItem(addKeyLocalStorage('data'))
      window.localStorage.removeItem(addKeyLocalStorage('isUserLoggedIn'))
      window.localStorage.removeItem(addKeyLocalStorage('ButtonLinkCommunity'))
      window.localStorage.removeItem(addKeyLocalStorage('autoLoginPhone'))
      window.localStorage.removeItem(addKeyLocalStorage('autoLoginPass'))
      return {}
    default:
      return state
  }
}

export default authReducer
