import React from 'react'
import { Link } from 'react-router-dom'
import * as sc from './FeedSmaill.styled'

export const FeedSmall = (props) => {
  const { driver } = props
  return (
    <div>
      <sc.Container type={props.type}>
        <Link style={{ textDecoration: 'none' }} to={`/detail-post/${driver.stationNewsId}`}>
          <sc.Content className="flex-center">
            <sc.Thumbnail bg={driver.stationNewsAvatar || ''} />
            <sc.Info>
              <sc.Title title={driver.stationNewsTitle}>{driver.stationNewsTitle}</sc.Title>
            </sc.Info>
          </sc.Content>
        </Link>
      </sc.Container>
      {!props.withoutDivider && <sc.Divider type={props.type} />}
    </div>
  )
}
