export const isBookingAvailable = ({station}) => {
    let _isAvailable = 0;
    if (station?.stationBookingConfig) {
        for (let i = 0; i < station?.stationBookingConfig.length; i++) {
            const _bookingConfig = station?.stationBookingConfig[i];
            if (_bookingConfig?.enableBooking) {
                _isAvailable = 1;
            }
        }
    }

    if (_isAvailable === 0) {
        _isAvailable = station?.availableStatus;
    }
    return _isAvailable;
  }