import { STATIONS_LIST } from '../constants/stationsList'

let initialState = {}

export default function hotnewsReducer(state = initialState, action) {
  switch (action.type) {
    case STATIONS_LIST: {
      return {
        ...state,
        ...action.data
      }
    }
    default:
      return state
  }
}
