import { ACTION_BANNER } from "constants/banner";

const initialState = {
  banners: [],
};

export default function bannersReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_BANNER.FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
      };
    default:
      return state;
  }
};
