import { combineReducers } from 'redux'
import setting from './setting'
import introduction from './stationIntroduction'
import hotnews from './hotnews'
import stations from './stationsList'
import authReducer from './auth'
import TotalVehiclereducer from './totalVehicle'
import bannersReducer from './banner'

import common from './common'

const rootReducer = combineReducers({
  setting,
  introduction,
  hotnews,
  stations,
  authReducer,
  TotalVehiclereducer,
  common,
  bannersReducer
})

export default rootReducer
