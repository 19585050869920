import { PageLayout } from 'components/PageLayout/PageLayout'
import React, { useEffect, useState, Suspense } from 'react'
import * as sc from './FeedDetail.styled'
import NewService from 'services/StationsNewsServiceFunctions'
import { convertTextToHTML } from 'helper/common'
import AspectRatio from 'react-aspect-ratio'
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb'
import { formatDate } from 'helper/formatDate'
import ResponsiveEmbed from 'react-responsive-embed'
import { StickyHeaderContainer } from 'components/elements/header'
import './../../assets/scss/typography.scss'
import { FeedDetailSharing } from './FeedDetailSharing'
import { FeedDetailView } from './FeedDetailView'
export const FeedDetailLayout = ({title='tin tức'}) => {
  const [detailPost, setDetailPost] = useState({})
  const pathname = window.location.pathname.split('/')
  const id = pathname[pathname.length - 1]
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shareUrl = `https://mobile.ttdkapi.ttdk.com.vn/AppSharing/StationNews/${id}`
  // const renderTableNavInfo = useMemo(() => {
  //   if (
  //     !introduction ||
  //     !introduction.categoriesNews ||
  //     introduction.categoriesNews.length < 1
  //   ) {
  //     return <Empty />;
  //   }

  //   return <TableNav driver={introduction.categoriesNews[1]} />;
  // }, [introduction?.categoriesNews]);
  // const renderTableNavData = useMemo(() => {
  //   if (
  //     !introduction ||
  //     !introduction.categoriesNews ||
  //     introduction.categoriesNews.length < 2
  //   ) {
  //     return <Empty />;
  //   }

  //   return <TableNav driver={introduction.categoriesNews[2]} />;
  // }, [introduction?.categoriesNews]);

  useEffect(() => {
    if (id) {
      NewService.userGetDetailById(parseInt(id)).then((result) => {
        if (result) {
          setDetailPost(result)
        }
      })

      // scroll to top
      window.scrollTo(0, 0)
    }
  }, [id])
  useEffect(() => {
    const popupContent = document.getElementById('popupContent')
    setTimeout(() => {
      popupContent.style.transform = 'translateY(0)'
    }, 100)
  }, [])
  // if (!introduction || !introduction.mostReadNews) {
  //   return <></>;
  // }

  // const renderMostViewed = () => {
  //   return introduction.mostReadNews.map((item) => (
  //     <React.Fragment key={item.stationNewsTitle}>
  //       <FeedSmall driver={item} />
  //     </React.Fragment>
  //   ));
  // };

  // const renderRelatedView = () => {
  //   if (!detailPost) {
  //     return <Empty />;
  //   }
  //   if (!Array.isArray(detailPost.relatedNews)) {
  //     return <Empty />;
  //   }
  //   if (introduction.generalNews.length === 0) {
  //     return <Empty />;
  //   }

  //   return detailPost.relatedNews.map((item, i) => (
  //     <React.Fragment key={item.stationNewsTitle}>
  //       <Feed
  //         withoutDivider={i + 1 === introduction.mostReadNews.length}
  //         driver={item}
  //       />
  //     </React.Fragment>
  //   ));
  // };

  const handleShare = () => {
    NewService.getShare(parseInt(id)).then((result) => {
      if (result) {
      }
    })
  }
  const showModal = () => {
    setIsModalOpen(true);
    handleShare()
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <sc.Container>
      <StickyHeaderContainer
        showLogo={false}
        showArrowLeft={true}
        title={title}
        color="white"
        backgroundColor="#051945"
        borderColor="transparent"
      />
      <FeedDetailView detailPost={detailPost} showModal={showModal}></FeedDetailView>
      <FeedDetailSharing shareUrl={shareUrl} detailPost={detailPost} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk}></FeedDetailSharing>
    </sc.Container>
  )
}

