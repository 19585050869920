import { PageLayout } from 'components/PageLayout/PageLayout'
import React, { useEffect, useState, Suspense } from 'react'
import * as sc from './../FeedDetail.styled'
import NewService from 'services/StationsNewsServiceFunctions'
import { convertTextToHTML } from 'helper/common'
import { formatDate } from 'helper/formatDate'
import ResponsiveEmbed from 'react-responsive-embed'
import { StickyHeaderContainer } from 'components/elements/header'
import './../../../assets/scss/typography.scss'
import { FeedDetailSharing } from '../FeedDetailSharing'
import { FeedDetailView } from '../FeedDetailView'
export const PartnerFeedDetailLayout = () => {
  const [detailPost, setDetailPost] = useState({})
  const pathname = window.location.pathname.split('/')
  const id = pathname[pathname.length - 1]
  const isEmbeddedCode = detailPost.embeddedCode?.includes('<iframe')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shareUrl=`https://mobile.ttdkapi.ttdk.com.vn/AppSharing/StationNews/${id}`

  useEffect(() => {
    if (id) {
      NewService.userGetDetailById(parseInt(id)).then((result) => {
        if (result) {
          setDetailPost(result)
        }
      })

      window.scrollTo(0, 0)
    }
  }, [id])
  useEffect(() => {
    const popupContent = document.getElementById('popupContent')
    setTimeout(() => {
      popupContent.style.transform = 'translateY(0)'
    }, 100)
  }, [])

  const handleShare = () =>{
    NewService.getShare(parseInt(id)).then((result) => {
      if (result) {
      }
    })
  }
  const showModal = () => {
    setIsModalOpen(true);
    handleShare()
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <sc.Container>
      <StickyHeaderContainer
        showLogo={false}
        showArrowLeft={true}
        title="Tiện ích đối tác"
        color="white"
        backgroundColor="#051945"
        borderColor="transparent"
      />
      <FeedDetailView detailPost={detailPost} showModal={showModal}></FeedDetailView>
      <FeedDetailSharing shareUrl={shareUrl} detailPost={detailPost} isModalOpen={isModalOpen}  handleCancel={handleCancel} handleOk={handleOk}></FeedDetailSharing>
    </sc.Container>
  )
}

const getVideoSrc = (embeddedCode) => {
  const matches = embeddedCode.match(/src="([^"]*)"/i)
  return matches && matches[1]
}
