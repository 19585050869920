import { smallScreenLimit } from 'constants/breakpoints'
import { blueSapphire } from 'constants/colors'
import { darkLiver } from 'constants/colors'
import { redLust } from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  width: 100%;
  height: 20px;
  margin: 8px 0;
`

export const RedCircle = styled.span`
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${redLust};

  margin-right: 8px;
`

export const Title = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: ${darkLiver};

  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${blueSapphire};
  }
`
export const Block = styled.div`
  width: 100%;
`
