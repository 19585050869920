import { smallScreenLimit } from 'constants/breakpoints'
import { tablet, galaxy_Fold } from 'constants/breakpoints'
import { cultured } from 'constants/colors'
import { blueSapphire } from 'constants/colors'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  @media (max-width: ${tablet}) {
    flex-flow: column-reverse;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin-bottom: 28px;
  margin-top: ${(props) => props.marginTop || 0}px;
  background: ${blueSapphire};
  border-radius: 12px 12px 0px 0px;
`
export const Share = styled.div`
  background-color: var(--primary-button-color);
  color: white;
  cursor: pointer;
  border-radius: 5px;
  min-width:75px;
  display:flex;
  justify-content:center;

`

export const Left = styled.div`
  flex: 1;
  padding-top: 33px;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.12);
  height: calc(100vh - 162px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 1;
  @media (max-width: ${tablet}) {
    padding-left: 16px;
    padding-top: 44px;
  }
`
export const Right = styled.div`
  flex: 1;
`

export const TableNavContainer = styled.div`
  margin-top: 40px;
`

export const Text = styled.span`
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  display: inline-block;
  @media (max-width: ${tablet}) {
    display: block;
  }
`

export const StationTitle = styled(Text)`
  margin-bottom: 8px;
  color:var(--gray-color);
  font-size: 17px !important;
  font-weight: 600;
`

export const StationInfo = styled(Text)`
  display: block;
`

export const StationsList = styled.div`
  padding: 16px;
  /* cursor: pointer; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  &:focus,
  &:hover,
  &.active {
    background-color: ${cultured};
  }

  &.disabled {
    opacity: 0.8;
  }

  & .disabledLink {
    pointer-events: none;
    opacity: 0.6;
    cursor: auto;
    color: var(--gray-color) !important;
  }

  @media (max-width: 994px) {
    padding: 16px;
  }
`

export const StationContainer = styled.div`
  transition: 0.5s;
  overflow: hidden;
  margin-bottom: 35px;
  &.off {
    height: 0;
    max-height: 0;
  }
  @media (max-width: ${tablet}) {
    flex-flow: column;
  }
`

export const PaginationContainer = styled.div`
  padding-right: 15px;
  justify-content: flex-end !important;
  padding-top: 42px;
  @media (max-width: ${smallScreenLimit}) {
    padding-bottom: 40px;
  }
  @media (max-width: ${tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
`

export const Align = styled.div`
  @media (max-width: 994px) {
    padding: 0 16px;
  }
`

export const Container = styled.div`
  justify-content: flex-start;
  @media (min-width: ${tablet}) {
    ${Left} {
      min-width: ${(props) => props.halfScreen}px;
    }

    ${Align} {
      padding-left: ${(props) => props.paddingLeft}px;
    }

    ${StationsList} {
      padding-left: ${(props) => props.paddingLeft}px;
    }
    ${PaginationContainer} {
      padding-left: ${(props) => props.paddingLeft}px;
    }
  }
  @media (max-width: ${tablet}) {
    height: 100%;
  }
`
