export const TARGET_ID= {
  ZALO:{
    id:1, 
    name:'zalo',
  },
  CUUHO:{
    id:2, 
    name:'cứu hộ sóng thần',
  },
  MOMO:{
    id:3, 
    name:'momo',
  },
  IPHATNGUOI:{
    id:4,
    name:'I Phạt Nguội',
  },
  MIC:{
    id:5, 
    name:'MIC',
  },
  GARAGETP:{
    id:6,
    name:'garage Tuấn phát',
  },
  CAMERAGT:{
    id:7, 
    name:'camera giao thông sài gòn',
  },
  GHDK:{
    id:8,
    name:'Gia hạn đăng kiểm',
  },
  GHBHTNDS:{
    id:9, 
  name:'Gia hạn BHTNDS',
  },
  TCPN:{
    id:10 ,
    name:'Tra cứu phạt nguội',
  },
  NHANVANAUTO:{
    id:11 ,
    name:'Nhân Văn Auto',
  },
  BANNER1:{
    id:12 ,
    name:'Banner1',
  },
  BANNER2:{
    id:13 ,
    name:'Banner2',
  },
  BANNER3:{
    id:14 ,
    name:'Banner3',
  },
  BANNER4:{
    id:15 ,
    name:'Banner4',
  },
  BANNER5:{
    id:16 ,
    name:'Banner5',
  },
  VUCAR:{
    id:17,
    name:'Mua bán xe cũ'
  },
  DINHGIAXE:{
    id:18,
    name:'Định giá xe'
  },
  BAOGIAOTHONG:{
    id:19,
    name:'Báo giao thông'
  },
  HTXHOASEN:{
    id:20,
    name:'Hợp tác xã Hoa Sen'
  },
  SALADIN:{
    id:21,
    name:'Saladin'
  },
  SALADIN_PVI:{
    id:22,
    name:'PVI x Saladin'
  },
  SALADIN_VNI:{
    id:23,
    name:'VNI x Saladin'
  },
  EPASS:{
    id:24,
    name:'EPASS'
  },
  DATXE:{
    id:25,
    name:'Đặt lịch bảo dưỡng'
  },
  HELLOBANNER:{
    id:26,
    name:'Hello Banner'
  },
  FOOTERBANNER:{
    id:27,
    name:'Footer Banner'
  },
  ADSBANNER:{
    id:28,
    name:'Ads Banner'
  },
  BANNERTTDK:{
    id:29,
    name:'Banner trung tâm'
  },
  CENTER:{
    id:30,
    name:'Danh sách trung'
  },
  INTERNAL:{
    id:31,
    name:'Nội bộ TTDK'
  },
  GARAGE:{
    id:32,
    name:'Bảo dưỡng ô tô'
  },
  INSPECTION_RESCUE:{
    id:33,
    name:'Cứu hộ đăng kiểm'
  },
  INSURANCE_COMPANY:{
    id:34,
    name:'Đơn vị bảo hiểm'
  },
  CONSULTING:{
    id:35,
    name:'Đơn vị tư vấn'
  },
  AFFILIATE:{
    id:36,
    name:'Đơn vị liên kết'
  },
  ADVERTISING:{
    id:37,
    name:'Đơn vị quảng cáo'
  },
  TRANSPORT_COOPERATIVE:{
    id:38,
    name:'Hợp tác xã vận tải'
  },
  USED_CAR_TRADING:{
    id:39,
    name:'Mua bán xe cũ'
  },
  SPARE_PARTS_DEALERSHIP:{
    id:40,
    name:'Mua bán phụ tùng ô tô'
  },
  PARKING_LOT:{
    id:41,
    name:'Bãi giữ xe'
  },
  VEHICLE_RENOVATION_COMPANY:{
    id:42,
    name:'Đơn vị cải tạo xe'
  },
  DRIVING_SCHOOL:{
    id:43,
    name:'Trường học lái xe'
  },
  CHAUFFEUR_SERVICE:{
    id:44,
    name:'Dịch vụ lái xe hộ'
  },
  PARTS_MANUFACTURING_CONSULTANCY:{
    id:45,
    name:'Tư vấn sản xuất phụ tùng xe'
  },
  DRIVER_HEALTH:{
    id:46,
    name:'Khám sức khoẻ lái xe'
  },
  CAR_EVALUATION_SERVICE:{
    id:47,
    name:'Dịch vụ định giá xe'
  },
  BOOKINGSUCCESSBANNER:{
    id:48,
    name:'Banner đặt lịch thành công'
  },
}