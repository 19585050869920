import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'
function FilterVihcle({setVihcleFilter,setCurrentPage}) {
  const { data: UserData } = useSelector((state) => state.authReducer)
  return (
    <ul className='filtervihcle'>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
          order: {
            key: "vehicleExpiryDay",
            value: "asc"
          }
        })
      }}>Ngày hết hạn đăng kiểm</li>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
          order: {
            key: "vehicleExpiryDateBHTNDS",
            value: "asc"
          }
        })
      }}>Ngày hết hạn BH TNDS</li>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
          order: {
            key: "vehicleExpiryDateBHTV",
            value: "asc"
          }
        })
      }}>Ngày hết hạn BH Thân vỏ</li>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
          order: {
            key: "vehicleCriminal",
            value: "desc"
          }
        })
      }}>Số lượng phạt nguội</li>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
          order: {
            key: "vehicleIdentity",
            value: "asc"
          }
        })
      }}>Biển số xe</li>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
          order: {
            key: "vehiclePlateColor",
            value: "asc"
          }
        })
      }}>Màu biển số</li>
      <li onClick={()=>{
        setCurrentPage(1)
        setVihcleFilter({
          limit: 5,
        })
          }}>Không sử dụng bộ lọc</li>
        </ul>
      )
    }

export default FilterVihcle
