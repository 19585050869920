import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import * as sc from './MenuTop.styled'

export const MenuTop = (props) => {
  const { driver } = props
  const ref = useRef(null)

  const renderHotNews = useMemo(() => {
    if (!Array.isArray(driver.data)) return <></>

    return driver.data.slice(0, 5).map((item) => {
      return (
        <sc.Block key={`${item.stationNewsId}-${item.stationNewsCategoryId}`}>
          <Link style={{ textDecoration: 'none' }} to={`/detail-post/${item.stationNewsId}`} className="flex-center">
            <sc.RedCircle />
            <sc.Title title={item.stationNewsTitle}>{item.stationNewsTitle}</sc.Title>
          </Link>
        </sc.Block>
      )
    })
  }, [driver])

  return (
    <marquee behavior="scroll" direction="left" onMouseOver={() => ref.current?.stop()} onMouseOut={() => ref.current?.start()} ref={ref}>
      <sc.Container>{renderHotNews}</sc.Container>
    </marquee>
  )
}
