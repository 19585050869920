import React, { useState } from 'react'
import LinkActionExternal from './LinkActionExternal'

export const BasicTextPhoneNumber=(text) => {
  let phonenum=text?.replace(/[^0-9]/g, "")
  let numLength =phonenum?.length
  let newText = []
  if(numLength == 20){
    newText.push(phonenum?.slice(0, 10));
    newText.push(phonenum?.slice(10, 20))
  }else if(numLength == 21){
    if(phonenum.slice(10, 12) == '00'){
      newText.push(phonenum?.slice(0, 11));
      newText.push(phonenum?.slice(11, 21))
    }else{
      if(phonenum.slice(10, 11) == '0'){
        newText.push(phonenum?.slice(0, 10));
        newText.push(phonenum?.slice(10, 21))
      }else{
        newText.push(phonenum?.slice(0, 11));
        newText.push(phonenum?.slice(11, 21))
      }
    }
  }else{
    newText.push(phonenum?.slice(0, 11));
  }
  return newText.map((item, index) => {
    return (
    <span>
      {(index + 1) % 2 === 0 ? <span> / </span> : ''}
      <LinkActionExternal link={`tel:${item}`}>
          <span>{item}</span>
      </LinkActionExternal>
    </span>
    )
  })
}