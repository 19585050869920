import { Button, Input, notification, Rate,Empty,Pagination, Modal } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import NewService from 'services/StationsNewsServiceFunctions'
import * as sc from './StationsLayout.styled'
import { StickyHeaderContainer } from 'components/elements/header'
import { ReactComponent as CloseIcon } from './../../assets/new-icons/Close2.svg'
import { ReactComponent as SuccessIcon } from './../../assets/new-icons/success2.svg'
import {  CopyOutlined, DownOutlined,} from '@ant-design/icons'
import './index.scss'
import { xoa_dau } from 'helper/common'
import BookingService from 'services/addBookingService'
import { WarningOutlined,CloseCircleOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import {STATION_TYPE} from './../../constants/global'
import SearchList from 'components/shared/search/SearchList'
import './index.scss'
import AreaByIP from 'services/getAreaByIP'
import { AspectRatio } from 'react-aspect-ratio'
import Slider from 'react-slick'
import queryString from 'querystring'
import LinkActionExternal from 'components/BasicComponent/LinkActionExternal'
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import { BasicTextPhoneNumber } from 'components/BasicComponent/BasicTextPhoneNumber'
import PopupMessage from 'components/PopupMessageAlert'
import BasicPopupList from 'components/BasicComponent/BasicPopupList'
import LoadingPopup from 'components/LoadingPopup'
import BasicTablePaging from 'components/BasicComponent/BasicTablePaging'
import { isBookingAvailable } from './StationsAction'
import { useSelector } from 'react-redux'
import { BANNER_TYPE } from 'constants/banner'
import { getBannerBySectionCache } from 'helper/getBannerBySectionCache'
import { useTranslation } from 'react-i18next'
import { ClickCountableArea } from 'components/BasicComponent/ClickCountableArea'
import { TARGET_ID } from 'constants/targetList'
const LIMIT = 20

export const StationsLayout = ({ history }) => {
  // const [currentMap, setCurrentMap] = useState()
  const location = useLocation();
  const { t: translation } = useTranslation()
  const searchparam = location.search
  const params = new URLSearchParams(searchparam)
  const [stations, setStations] = useState({})
  const [changeField, setChangeField] = useState('')
  const [isOpenSelectionModal, setIsOpenSelectionModal] = useState(false)
  const [currentStation, setCurrentStation] = useState()
  const [detailStationBtn, setDetailStationBtn] = useState(false)
  const [stationId, setStationId] = useState('')
  const [totalData, setTotalData] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [firstPage, setFirstPage] = useState(false)
  const [isModalErrOpen, setIsModalErrOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [stationsType, setStationsType] = useState(params.get('type') || STATION_TYPE.TTDK.stationType)
  const [stationsName, setStationsName] = useState(params.get('name') || translation('list-station'))
  const [stationArea, setStationArea] = useState(params.get('area') || undefined)
  const [searchText, setSearchText] = useState(params.get('searchtext') || "")
  // const [selectedStation, setSelectedStation] = useState({})
  const d = new Date();
  let dayOfWeek = d.getDay() -1
  const [filter, setFilter] = useState({
    limit: 20,
    searchText: searchText,
    skip:0,
    filter: {
      stationType:stationsType,
      stationArea:stationArea
    }
  })
  const [activeIcon, setActiveIcon] = useState(1)
  const [serviceProvided, setServiceProvided] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [scheduleBtn,setScheduleBtn]=useState(true)
  const [area,setArea]=useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [clickCountStationType, setClickCountStationType] = useState('');

  // const [currentLimit, setCurrentLimit] = useState(20)
  const [listStationArea, setListStationArea] = useState([])

  // const [open, setOpen] = useState(false)

  const [isAtBottom, setIsAtBottom] = useState(false)

  const [stationsBanner, setStationsBanner] = useState([])
  const CLICK_STATIONS_TYPE =() => {
    switch (stationsType) {
      case "1":
        return TARGET_ID.CENTER.id
      case "2":
        return TARGET_ID.INTERNAL.id
      case "3":
        return TARGET_ID.GARAGE.id
      case "4":
        return TARGET_ID.INSPECTION_RESCUE.id
      case "5":
        return TARGET_ID.INSURANCE_COMPANY.id
      case "6":
        return TARGET_ID.CONSULTING.id
      case "7":
        return TARGET_ID.AFFILIATE.id
      case "8":
        return TARGET_ID.ADVERTISING.id
      case "9":
        return TARGET_ID.TRANSPORT_COOPERATIVE.id
      case "10":
        return TARGET_ID.USED_CAR_TRADING.id
      case "11":
        return TARGET_ID.SPARE_PARTS_DEALERSHIP.id
      case "12":
        return TARGET_ID.PARKING_LOT.id
      case "13":
        return TARGET_ID.VEHICLE_RENOVATION_COMPANY.id
      case "14":
        return TARGET_ID.DRIVING_SCHOOL.id
      case "15":
        return TARGET_ID.CHAUFFEUR_SERVICE.id
      case "16":
        return TARGET_ID.PARTS_MANUFACTURING_CONSULTANCY.id
      case "17":
        return TARGET_ID.DRIVER_HEALTH.id
      case "18":
        return TARGET_ID.CAR_EVALUATION_SERVICE.id
      default:
        return TARGET_ID.CENTER.id
    }
  }
  useEffect(() => {
    getBannerBySectionCache(BANNER_TYPE[stationsType]).then(data =>{
      setStationsBanner(data)
    })
}, []);

  const handleCoppyLink=(textToCopy)=>{
    handleCancel();
    setIsModalErrOpen(true)
    setErrorMessage('Sao chép thành công!')
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy)
    } else {
      // text area method
      let textArea = document.createElement('textarea')
      textArea.value = textToCopy
      // make the textarea out of viewport
      textArea.style.position = 'fixed'
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej()
        textArea.remove()
      })
    }
  }
  // const showDrawer = () => {
  //   setOpen(true)
  // }
  // const onClose = () => {
  //   setOpen(false)
  // }
  const handleSearch = (value) => {
    setCurrentPage(1)
    setFirstPage(!firstPage)
    setSearchText(value)
    setFilter({ ...filter, limit: 20, searchText: value,skip:0 })
  }
  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum)
    const skip = pageNum * LIMIT - LIMIT
    setFilter({ ...filter,skip:skip })
  }
  useEffect(() => {
    getAreaByIP()
    getStationAreas()
    function handleScroll() {
      const footerEl = document.querySelector('footer')
      let footerHeight = 0
      if (footerEl) {
        footerHeight = footerEl.clientHeight
      }
      const scrollPosition = window.scrollY
      const viewportHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight
      const isBottom = scrollPosition + viewportHeight >= documentHeight - footerHeight
      setIsAtBottom(isBottom)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // useEffect(() => {
  //   if (isAtBottom && window.location.href.indexOf('/stations') > -1) setFilter({ ...filter, limit: filter.limit + 20 })
  // }, [isAtBottom, window.location.href])

  useEffect(() => {
    getStation()
  }, [filter])
  const getAreaByIP = () => {
    AreaByIP.getAreaByIP().then((result) => {
      const { statusCode,data } = result
      if (statusCode == 200) {
        if(data.stationArea){
          filter.filter.stationArea = data.stationArea
          setArea(data.stationArea)
          getStation(filter)
        }
      }
      return result
    })
  }
  const getStationAreasList=(searchText) => {
    if(!searchText){
      getStationAreas()
    }
    let items = []
    listStationArea.map(item => {
      if(item.value.toLowerCase().indexOf(searchText) !== -1){
        items.push(item)
      }
    });
    if(items.length > 0){
      setListStationArea(items)
    }else{
      setListStationArea([])
    }
  }
  const getStationData=(area) => {
    setArea(area.value)
    let newFilter = {
      ...filter.filter,
      stationArea: area.value,
    }
    if(!area.code){
      newFilter = {
        ...filter.filter,
        stationArea: undefined,
      }
    }
    setFilter({
      ...filter,
      filter: newFilter,
      skip:0
    })
    setCurrentPage(1)
    setFirstPage(!firstPage)
  }
  function getStationAreas() {
    BookingService.getStationAreaList()
      .then((data) => {
        let tmp = data || []
        if (tmp.length > 0)
          tmp.forEach((element) => {
            element.label = <div style={{ fontWeight: 'normal' }}>{element.value}</div>
            element.value = element.value
          })
        setListStationArea([
          ...[
            {
              label: <div style={{ fontWeight: 'normal' }}>{translation('all-vntId')}</div>,
              value: 'Tất cả khu vực'
            }
          ],
          ...tmp
        ])
      })
      .catch(() => {
        notification.error(translation('get-data-fail'))
      })
  }
  
  const getStation = () => {
    setIsVisible(true)
    NewService.getStationsList(filter)
      .then((result) => {
        setIsVisible(false)
        if (result) {
          setStations({
            ...stations,
            ...result
          })
          setTotalData(result.total)
        }
        return result
      })
      .then((stations) => {
        if (stations?.data && stations?.data?.[0]) {
          // setCurrentMap(stations.data[0].stationMapSource)
          setCurrentStation(`${stations?.data[0]?.stationsName}-${stations?.data[0]?.stationUrl}`)
          // setSelectedStation(stations.data[0])
        }
      })
  }
  const settingSilde = {
		dots: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed:5000,
	};


  const handleFilterService=(e)=>{
    let newFilter = filter
    if(serviceProvided == e){
      setServiceProvided('')
      newFilter.filter.serviceType = undefined
    }else{
      setServiceProvided(e)
      newFilter.filter.serviceType = e
    }
    setFilter(newFilter)
    getStation(newFilter)
  }
  const handleFilterStationType=(e) => {
    setCurrentPage(1)
    setScheduleBtn(e)
    let newFilter = filter
    if(activeIcon == e){
      setActiveIcon('')
      newFilter.filter.stationType = undefined
      newFilter.skip = 0
    }else{
      setActiveIcon(e)
      newFilter.filter.stationType = e
      newFilter.skip = 0
    }
    setFilter(newFilter)
    getStation(newFilter)
  }
  const handleShare = (id) =>{
    NewService.getShare(parseInt(id)).then((result) => {
      if (result) {
      }
    })
  }
  const showModal = (id) => {
    setIsModalOpen(true);
    handleShare(id)
     setShareUrl(`https://sharing.ttdk.com.vn/AppSharing/Stations/${id}`)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [telTo,setTelTo]=useState([])

  useEffect(()=>{
    setTimeout(() => {
      if(detailStationBtn){
        history.push(`/station/${stationId}`)
      }  
    }, 100);
  },[detailStationBtn])


  const renderStations = useMemo(() => {
    if (!stations.data || stations.data.length === 0) return <><Empty description="Hiện tại chưa có trung tâm phù hợp" /></>

    return stations.data.map((item, i) => {
      if (_.isEmpty(item)) return <></>
      const active = item?.stationsName + '-' + item?.stationUrl === currentStation
      let _enableBooking = isBookingAvailable({station: item});
      
      const stationStatusActived = item?.stationStatus

      const statusComponent = (() => { 
        if (!stationStatusActived) {
          return (
            <>
              <Link to={`/station/${item?.stationCode}`} className="ai-c status-box" style={{ backgroundColor: 'var(--error-btn-color)' }}>
                <WarningOutlined style={{ marginRight: 5,width:'16px',height:'16px' }} /> <span>{translation('station-unactive')}</span>
              </Link>
            </>
          )
        }

        if (!_enableBooking) {
          return (
            <>
              <Link to={`/station/${item?.stationCode}`} className="ai-c status-box" style={{ backgroundColor: 'var(--stop-receiving-color)' }}>
                <CloseIcon style={{ marginRight: 5 }} /> <span>{translation('book-stop')}</span>
              </Link>
            </>
          )
        }

        return (
          <Link to={`/station/${item?.stationCode}`} className="ai-c status-box" style={{ backgroundColor: 'var(--success-btn-color)' }}>
            <SuccessIcon style={{ marginRight: 5, marginLeft: 5 }} />
            <span style={{ marginLeft: 5 }}>{translation('booking-active')}</span>
          </Link>
        )
      })()

      
      return (
        <sc.StationsList
          key={`${item?.stationsName}-${item?.stationUrl}-${i}`}
          className={[active ? 'active' : '', !item?.stationMapSource ? 'disabled' : ''].join(' ') + 'list-station'}
          onClick={() => {
            // item.stationMapSource && setCurrentMap(item.stationMapSource)
            setCurrentStation(`${item?.stationsName}-${item?.stationUrl}`)
            // setDetailStationBtn(true)
            // setStationId(item?.stationCode)
            // setSelectedStation(item)
          }}>
            <div className="d-flex j-sb">
              <Link to={`/station/${item?.stationCode}`} style={{width:'100%'}}>
                <sc.StationTitle >{item?.stationsName}</sc.StationTitle>
              </Link>  
              <div>
                <sc.Share onClick={() =>{showModal(item?.stationCode)}}>{translation('share')}</sc.Share>
              </div> 
              {/* <div style={{ width: 17, marginLeft: 10 }}>{(!active && <ArrowDownIcon />) || <ArrowUpIcon />}</div> */}
            </div>
            <div className="d-flex j-sb mb-2" 
                onClick={() => {
                setCurrentStation(`${item?.stationsName}-${item?.stationUrl}`)
                setDetailStationBtn(true)
                setStationId(item?.stationCode)
              }}>
                <div className='station-address'>{translation('address')}: <LinkActionExternal className='address' link={`https://www.google.com/maps/dir//${item.stationsAddress}`} target="_blank">{item?.stationsAddress}</LinkActionExternal></div>
            </div>
            <div className="d-flex j-sb working-time">
              <div>
                {item?.stationWorkTimeConfig[dayOfWeek]?.enableWorkDay ? 
                <div 
                  onClick={() => {
                  setCurrentStation(`${item?.stationsName}-${item?.stationUrl}`)
                  setDetailStationBtn(true)
                  setStationId(item?.stationCode)
                }}>{translation('work-time')}: <span className='working-time-text'>{item?.stationWorkTimeConfig[dayOfWeek]?.startTime}-{item?.stationWorkTimeConfig[dayOfWeek]?.endTime}</span></div> : <div><span className='' style={{color:'var(--error-btn-color)'}}>{translation('work-timeout')}</span></div>} 
                {/* {scheduleBtn==STATION_TYPE.CUUHO.stationType ? (
                  <div>Số điện thoại: <LinkActionExternal link={`tel:${telTo}`}><span className='working-time-text'>{checkPhone(item?.stationsHotline)}</span></LinkActionExternal></div> 
                ):( */}
                <div>{translation('landing.phoneNumber')}: <span className='working-time-text'>{BasicTextPhoneNumber(item?.stationsHotline)}</span></div> 
                {/* <div>Số điện thoại: <LinkActionExternal ><span className='working-time-text'>{checkPhone(item?.stationsHotline)}</span></LinkActionExternal></div>  */}
                {/* )} */}
              </div>
              <Rate disabled defaultValue={item?.ratingPoint} allowHalf />
            </div>
            {stationsType==STATION_TYPE.TTDK.stationType ? (
            <div className="d-flex justify-content-between align-items-center mt-1 group-btn" 
              onClick={() => {
                setCurrentStation(`${item?.stationsName}-${item?.stationUrl}`)
                setDetailStationBtn(true)
                setStationId(item?.stationCode)
              }}>
              {statusComponent}
              <Link
                to={`/booking?${queryString.stringify({
                  stationArea: item.stationArea,
                  stationCode: item.stationCode,
                  stationsId: item.stationsId,
                  stationsAddress: item.stationsAddress
                })}`}
                 className={`text-small text-uppercase schedule-btn ${!item.stationStatus ? "disabledLink" : ""}`}
                onClick={e => {
                  // Bạn có thể thêm điều kiện để chặn sự kiện, ví dụ:
                  if (!item.stationStatus) {
                    e.preventDefault(); // Chặn sự kiện mặc định
                  }
                }}
              >
                {translation('book-now')}
              </Link>
              </div>
            ):<div className="mb-4"></div>}
        </sc.StationsList>
      )
    })
  }, [stations, currentStation])

  return (
    <sc.Container>
      {isVisible && (
        <LoadingPopup />
      )}
      <StickyHeaderContainer
        showLogo={false}
        showArrowLeft={true}
        title={translation(stationsName)}
        color="white"
        backgroundColor="#051945"
        borderColor="transparent"
        // customBackPath='/'
      />
      <div style={{ maxWidth: 600, margin: 'auto', width: '100%' }}>
      <div>
        {stationsBanner?.length ?
          (
            <div className={`bottom-banner`} style={{ overflow: 'hidden' }}>
            <ClickCountableArea Id={CLICK_STATIONS_TYPE()}  className='custom-slide' link={stationsBanner[0]?.bannerUrl || "#"} target={stationsBanner[0]?.bannerUrl ? "_blank" : "_self"}>
              <img src={stationsBanner[0].bannerImageUrl} alt={stationsBanner[0].bannerName} />
            </ClickCountableArea>
        </div>
          ) : <></>}
          </div>

        <div>
          <sc.StationContainer>
          <div className='search-item'>
            <div className="search-form d-flex ai-c j-sb search">
              {/* <Input
                className="login__input search search-input"
                onChange={(e) => {
                  const value = e.target.value
                  setSearchText(value)
                }}
                placeholder="Tìm kiếm"
              /> */}
              {/* <Button type="button" className="search search-icon" onClick={() => setFilter({ ...filter, limit: 20, searchText: searchText })}>
                <SearchOutlined />
              </Button> */}
                
              <SearchList placeholder={translation('input-station')} className="search search-input w-100" onSearch={handleSearch} />
            </div>
            <div className="search-form" 
              onClick={()=>{
                setIsOpenSelectionModal(true);
                setChangeField('vntStation')
              }}>
              <Input
                className="cs-select c-ss"
                isSearchable={true}
                readOnly
                value={area}
                filterOption={(input, option) => {
                  return xoa_dau((option?.value ?? '').toLowerCase()).includes(xoa_dau(input.toLowerCase()))
                }}  
                placeholder={translation('vntId')}
                options={listStationArea}
                suffix={<DownOutlined className='dropdown-icon' />}
              />
            </div>
          </div>
          {/* <div className='station-more'>
            {Object.values(STATION_TYPE).map(item=>(
              <div className='more-icon pointer'>
                <div onClick={()=>handleFilterStationType(item.stationType)} className={`${activeIcon == item.stationType ? 'active-icon' : ''}`}>
                  {item.icon}  
                </div>
                <div className='text-small'>{item.value}</div>
              </div>
            ))}
          </div> */}
          {/* tạm ẩn phần lọc theo dịch vụ */}
          {/* <div className='search-service'>
            <div className='search-service-title title-small'>Dịch vụ cung cấp</div>
            <div className='search-service-provided'>
              {SERVICE_PROVIDED && SERVICE_PROVIDED.map(item=>(
                <div onClick={()=> handleFilterService(item.serviceType)} className={`${serviceProvided == item.serviceType ? 'active-service' : ''}`}>{item.value}</div>
              ))}
            </div>
          </div> */}
          
            {renderStations}
          </sc.StationContainer>
          {/* 
          <div>
            <Drawer
              className="map-drw"
              closable={false}
              headerStyle={{ display: 'none' }}
              width="100%"
              placement="right"
              onClose={onClose}
              visible={open}>
              <div className="pdt-60"></div>
              <div className={`sticky-header d-flex ai-c j-sb`} style={{ backgroundColor: 'white', borderColor: '#0C42BC' }}>
                <div className="d-flex ai-c">
                  <LeftIcon style={{ marginRight: 10 }} onClick={() => setOpen(false)} />
                  <div style={{ color: '#051945' }} className="title">
                    Bản đồ
                  </div>
                </div>
              </div>
              <div style={{ maxWidth: 600, margin: 'auto' }}>
                <div className="map" dangerouslySetInnerHTML={{ __html: currentMap }} />
                {open && (
                  <div className="bottomInf" style={{ maxWidth: 600, margin: 'auto' }}>
                    <div className="title-small title-st">{`${selectedStation.stationsName}`}</div>
                    <div className="direct d-flex ai-c j-sb">
                      <a
                        href={`https://www.google.com/maps/dir//${selectedStation.stationsHotline}-${selectedStation.stationsAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="direc-it">
                        <DirectionsIcon /> <span className="text-small">Đường đi</span>
                      </a>
                      {selectedStation.stationsHotline && (
                        <a href={`tel:${selectedStation.stationsHotline}`} target="_blank" rel="noopener noreferrer" className="direc-it">
                          <CallIcon /> <span className="text-small">Gọi</span>
                        </a>
                      )}

                      <div className="direc-it active" onClick={() => showDrawer()}>
                        <MapIcon /> <span className="text-small">Bản đồ</span>
                      </div>
                    </div>
                    <div className="text-small more-information">
                      <sc.StationInfo>{selectedStation.stationsAddress && `Địa chỉ: ${selectedStation.stationsAddress}`}</sc.StationInfo>
                      <sc.StationInfo>{selectedStation.stationsHotline && `SĐT: ${selectedStation.stationsHotline}`}</sc.StationInfo>
                      <sc.StationInfo>{selectedStation.stationsEmail && `Email: ${selectedStation.stationsEmail}`}</sc.StationInfo>
                    </div>
                  </div>
                )}
              </div>
            </Drawer>
          </div> */}
            <>
              <BasicTablePaging handlePaginations={handleChangePage} firstPage={firstPage} count={stations?.data?.length < LIMIT}></BasicTablePaging>
            </>
          <Modal title={translation('share')} style={{maxWidth:'280px',width:'100%'}} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{justifyContent:'center',display:'flex',gap:'15px'}}>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon onClick={()=>handleCancel()} size={40} round={true}></FacebookIcon>
              </FacebookShareButton>
              <EmailShareButton url={shareUrl} subject={'Trung tâm đăng kiểm ô tô - TTDK'}>
                <EmailIcon onClick={()=>handleCancel()} size={40} round={true}></EmailIcon>
              </EmailShareButton>
              <TelegramShareButton url={shareUrl}>
                <TelegramIcon onClick={()=>handleCancel()} size={40} round={true}></TelegramIcon>
              </TelegramShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon onClick={()=>handleCancel()} size={40} round={true}></TwitterIcon>
              </TwitterShareButton>
              <Button className='btn-coppy' onClick={()=>handleCoppyLink(shareUrl)}>
                <CopyOutlined style={{fontSize:'18px'}} />
              </Button>
            </div>
          </Modal>
          {isModalErrOpen &&
            <PopupMessage isModalOpen={isModalErrOpen} history={history} onClose={() => {setIsModalErrOpen(false)}} text={errorMessage} ></PopupMessage>
          }
          <BasicPopupList 
            listData={listStationArea}
            title={translation('choose-vntId')} 
            changeField={changeField} 
            onSearchCallback={getStationAreasList}
            showSearch
            isOpenPopupList={isOpenSelectionModal}
            onSelectedItemChanged={getStationData}
            onClose={() => {setIsOpenSelectionModal(false)}}>
          </BasicPopupList>
        </div>
      </div>
    </sc.Container>
  )
}
