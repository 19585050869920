import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import * as sc from './Category.styled'

export const Category = (props) => {
  const { driver } = props

  const renderCategoryWithLink = useMemo(() => {
    return !driver?.stationNewsCategoryId ? (
      <sc.Name>{props.name}</sc.Name>
    ) : (
      <Link to={`/new/${driver.stationNewsCategoryId}`}>
        <sc.Name>{props.name}</sc.Name>
      </Link>
    )
  }, [props])

  return (
    <sc.Container withDivider={props.withDivider || false} className="flex-center" marginBottom={props.marginBottom} id={props.id}>
      {renderCategoryWithLink}
      <div className="flex-center">
        {props.categories?.map((category, i) => (
          <>
            <sc.Link>{category}</sc.Link>
            {i !== props.categories?.length - 1 && <sc.RedCircle />}
          </>
        ))}
      </div>
    </sc.Container>
  )
}
