import { redLust } from 'constants/colors'
import { blueSapphire } from 'constants/colors'
import { blackRaisin } from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  justify-content: space-between;

  margin-bottom: ${(props) => props.marginBottom || 0}px;
  ${(props) =>
    props.withDivider &&
    `
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 2px;
  `};

  > a:hover {
    > span {
      color: ${blueSapphire};
    }
  }
`

export const Name = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  text-transform: uppercase;
  color: ${blackRaisin};

  &::before {
    content: '';
    width: 4px;
    height: 14px;
    min-width: 4px;
    min-height: 14px;

    background: #0a3daf;
    margin-right: 12px;
    display: inline-block;
  }
`

export const RedCircle = styled.span`
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${redLust};

  margin-left: 12px;
  margin-right: 12px;
`

export const Link = styled(Name)`
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;

  &::before {
    content: none;
  }
`
