import moment from 'moment'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'

export const formatDate = (date) => {
  return moment(date || new Date()).format(DATE_DISPLAY_FORMAT)

  // TODO: consider to remove moment lib
  // const dateObj = new Date(date);
  // const dayNumber = dateObj.getDate();

  // const {day, month, year} = {
  //   day: dayNumber >= 10 ? `${dayNumber}` : `0${dayNumber}`,
  //   month: dateObj.getMonth() + 1,
  //   year: dateObj.getFullYear(),
  // };

  // return `${day}/${month}/${year}`;
}
