import { Empty, Pagination } from 'antd'
import { Category } from 'components/Category/Category'
import { Feed } from 'components/Feed/Feed'
import { FeedSmall } from 'components/Feed/FeedSmall'
import { MenuTop } from 'components/MenuTop/MenuTop'
import { PageLayout } from 'components/PageLayout/PageLayout'
import { Slider } from 'components/Slider/Slider'
import { QuickNav } from 'components/Widgets/QuickNav'
import { TableNav } from 'components/Widgets/TableNav'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import NewService from 'services/StationsNewsServiceFunctions'
import * as sc from './NewsLayout.styled'
import BasicTablePaging from 'components/BasicComponent/BasicTablePaging'

export const NewsLayout = (props) => {
  const { t: translation } = useTranslation()

  const { introduction } = props
  const pathname = window.location.pathname.split('/')
  const categoryId = pathname[pathname.length - 1]
  const categories = useSelector(
    (state) =>
      state?.introduction?.categoriesNews.find((item) => {
        return item.stationNewsCategoryId === Number(categoryId)
      }) || {}
  )
  const [dataPost, setDataPost] = useState({
    data: [],
    total: 0
  })
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    NewService.userGetList(categoryId, skip, 10).then((result) => {
      if (result && result.data && result.data.length > 0) {
        setDataPost({ ...result })
      } else {
      }
    })
  }, [categoryId])

  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum)
    const newSkip = (pageNum - 1) * 8
    NewService.userGetList(categoryId, newSkip, 8).then((result) => {
      if (result) {
        setSkip(newSkip)
        setDataPost({
          ...dataPost,
          ...result
        })
      }
    })
  }

  const renderMostViewed = () => {
    if (!introduction.mostReadNews) return <Empty />

    if (!introduction.mostReadNews.length === 0) {
      return <Empty />
    }
    return introduction.mostReadNews.map((item) => (
      <React.Fragment key={item.stationNewsTitle}>
        <FeedSmall driver={item} />
      </React.Fragment>
    ))
  }

  const renderGeneralNews = () => {
    if (!dataPost.data.length === 0) {
      return <Empty />
    }

    return dataPost.data.map((item) => (
      <React.Fragment>
        <Feed driver={item} />
      </React.Fragment>
    ))
  }

  const renderTableNavInfo = useMemo(() => {
    if (!introduction || !introduction.categoriesNews || introduction.categoriesNews.length < 1) {
      return <Empty />
    }

    return <TableNav driver={introduction.categoriesNews[1]} />
  }, [introduction?.categoriesNews])
  const renderTableNavData = useMemo(() => {
    if (!introduction || !introduction.categoriesNews || introduction.categoriesNews.length < 2) {
      return <Empty />
    }

    return <TableNav driver={introduction.categoriesNews[2]} />
  }, [introduction?.categoriesNews])

  const renderMenuTop = useMemo(() => {
    if (!introduction || !introduction.categoriesNews || introduction.categoriesNews.length < 1) {
      return <></>
    }

    return <MenuTop driver={introduction.categoriesNews[0]} />
  }, [introduction?.categoriesNews])

  const renderSlider = useMemo(() => {
    if (!introduction || !introduction.categoriesNews || introduction.categoriesNews.length < 1) {
      return <></>
    }

    return <Slider driver={introduction.categoriesNews[0]} />
  }, [introduction?.categoriesNews])

  return (
    <sc.Container>
      <PageLayout>
        {renderMenuTop}
        {renderSlider}
      </PageLayout>
      <PageLayout>
        <sc.Content>
          <sc.Left>
            <Category withDivider name={categories?.stationNewsCategoryTitle || ''} marginBottom={28} />
            {renderGeneralNews()}

            <sc.PaginationContainer>
            <>
              <BasicTablePaging handlePaginations={handleChangePage} count={dataPost?.data?.length < 10}></BasicTablePaging>
            </>
            </sc.PaginationContainer>
          </sc.Left>
          <sc.Right>
            <QuickNav driver={props.setting} marginBottom={36} />
            <Category name={translation('category.most-viewed')} marginBottom={20} />
            {renderMostViewed()}

            <sc.TableNavContainer>{renderTableNavInfo}</sc.TableNavContainer>
            <sc.TableNavContainer>{renderTableNavData}</sc.TableNavContainer>
          </sc.Right>
        </sc.Content>
      </PageLayout>
    </sc.Container>
  )
}
