import Request from './request'

export default class CommonService {
  static async GetMetaData(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/SystemConfigurations/getMetaData',
        data
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
}