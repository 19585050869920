import { HOTNEWS } from 'constants/hotnews'

let initialState = {}

export default function hotnewsReducer(state = initialState, action) {
  switch (action.type) {
    case HOTNEWS: {
      return {
        ...state,
        ...action.data
      }
    }
    default:
      return state
  }
}
