import React, { useEffect,  } from 'react'

const LinkActionExternal = ({link,children,className,style,onClick}) => {

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <>
      <a className={className} target='blank' rel="noopener noreferrer" onClick={onClick} style={style} href={link}>{children}</a>
    </>
  )
}

export default LinkActionExternal;