import React from 'react'
import { Link } from 'react-router-dom'
import * as sc from './TableNav.styled'

export const TableNav = (props) => {
  const { driver } = props

  if (driver?.data?.length === 0) {
    return <></>
  }

  return (
    <sc.Container>
      <sc.HeaderLine />
      <sc.Header className="flex-center">
        <Link to={`/new/${driver?.stationNewsCategoryId}`}>{driver?.stationNewsCategoryTitle}</Link>
      </sc.Header>
      <sc.Content>
        {driver?.data &&
          driver?.data.map((item) => (
            <Link style={{ textDecoration: 'none' }} to={`/detail-post/${item?.stationNewsId}`} key={`TableNav-${item?.stationNewsId}`}>
              {item?.stationNewsTitle}
            </Link>
          ))}
      </sc.Content>
    </sc.Container>
  )
}
