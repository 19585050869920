import { ReCaptcha } from 'components/ReCaptcha/ReCaptcha'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import LoginService from 'services/loginService'
import * as sc from './PenaltyForm.styled'

export const PenaltyForm = () => {
  const { state } = useLocation()
  const { t: translation } = useTranslation()

  const [captchaValid, setCaptchaValid] = useState(false)
  const [licensePlates, setLicensePlates] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onCaptchaChange = () => {
    setCaptchaValid(true)
  }

  async function handleFind({ licensePlates }) {
    try {
      setLoading(true)
      error && setError('')

      const result = await LoginService.getDetailPunish({
        plateNumber: licensePlates
      })

      if (result && result.length > 0) {
        setData(result)
      } else {
        data.length > 0 && setData([])
        setError(translation('management.none'))
      }
    } catch (error) {
      setError(translation('landing.error'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <sc.Container>
      <sc.Form
        onSubmit={(e) => {
          e.preventDefault()
          handleFind({ licensePlates })
        }}>
        <sc.FormTitle className="flex-center">{translation('header.punish')}</sc.FormTitle>
        <sc.FormContent>
          <sc.Label>{translation('landing.license-plates')}</sc.Label>
          <sc.Input
            placeholder={translation('stations.license-plates')}
            type="text"
            onChange={(e) => setLicensePlates(e.target.value)}
            value={licensePlates}
          />

          <ReCaptcha onChange={onCaptchaChange} />
          <sc.Button disabled={!captchaValid || !licensePlates || loading}>{translation('stations.check')}</sc.Button>

          {loading && (
            <sc.Loading className="flex-center">
              <div className="loader" />
            </sc.Loading>
          )}

          <sc.Row className="row">
            {data &&
              data.length > 0 &&
              data.map((item, i) => {
                return (
                  <React.Fragment key={item.licenseNumber + item.violationTime}>
                    <p>
                      <b>{translation('penaltyForm.licenseNumber')}: </b>
                      <span>{item.licenseNumber}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.specs')}: </b>
                      <span>{item.specs}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.vehicleType')}: </b>
                      <span>{item.vehicleType}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.violationTime')}: </b>
                      <span>{item.violationTime}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.violationAddress')}: </b>
                      <span>{item.violationAddress}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.status')}: </b>
                      <span>{item.status}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.provider')}: </b>
                      <span>{item.provider}</span>
                    </p>
                    <p>
                      <b>{translation('penaltyForm.contactPhone')}: </b>
                      <span>{item.contactPhone}</span>
                    </p>
                    {i + 1 !== data.length && <hr style={{ width: '100%' }} />}
                  </React.Fragment>
                )
              })}
            {error && <sc.Error className="error">{error}</sc.Error>}
          </sc.Row>
        </sc.FormContent>
      </sc.Form>
    </sc.Container>
  )
}
